import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import { startOfDay, endOfDay, format } from "date-fns"; // Add this import

export default function ReportMoveInInspectionVariance() {
    const [properties, setProperties] = useState([]);
    const [fromDate, setFromDate] = useState(startOfDay(new Date())); // Initialize with today
    const [toDate, setToDate] = useState(endOfDay(new Date())); // Initialize with today
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                let responseData = [
                    { id: 0, value: 0, label: "All" },
                    { id: -1, value: -1, label: "No Building" },
                ];
                setProperties(responseData.concat(response.data));
            })
            .catch((error) => {
                alert("properties failed");
            });
    }, []);

    const formatDateForBackend = (date) => {
        if (!date) return null;
        return format(date, "yyyy-MM-dd");
    };

    const fetchReportData = async (values) => {
        try {
            setLoading(true);
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}reports/view_move_in_inspection_variance`,
                data: {
                    property_id: values.property_id,
                    from_date: formatDateForBackend(fromDate),
                    to_date: formatDateForBackend(toDate),
                },
            });
            setReportData(response.data);
        } catch (error) {
            console.error("Failed to fetch report data:", error);
            alert("Failed to load report data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const ReportTable = ({ data }) => {
        if (!data || !data.length) return null;

        return (
            <div className="mt-8 overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Property</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Move In Booked</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Moved In</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Variance</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">%</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((row, index) => (
                            <tr key={index} className={row.property === "Totals" ? "bg-gray-50 font-bold" : ""}>
                                <td className="px-6 py-4 whitespace-nowrap">{row.property}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{row.date}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{row.move_in_booked}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{row.moved_in}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{row.variance}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{(row.percentage * 100).toFixed(2)}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Move In Inspection Variance Report</h1>

                <Formik
                    initialValues={{
                        property_id: 0,
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        if (!fromDate || !toDate) {
                            alert("Please fill in dates");
                            setSubmitting(false);
                            return;
                        }

                        try {
                            const response = await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}reports/export_move_in_inspection_variance`,
                                responseType: "blob",
                                data: {
                                    property_id: values.property_id,
                                    from_date: formatDateForBackend(fromDate),
                                    to_date: formatDateForBackend(toDate),
                                },
                            });

                            const href = URL.createObjectURL(response.data);
                            const link = document.createElement("a");
                            link.href = href;
                            link.setAttribute("download", "Move In Inspection Variance.xlsx");
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        } catch (error) {
                            console.error("Export failed:", error);
                            alert("Failed to generate report. Please try again.");
                        }

                        setSubmitting(false);
                    }}
                >
                    {({ values, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5 ">
                                    <SettingsSelect label="Property" name="property_id" onChange={setFieldValue} value={values.property_id} options={properties} />

                                    <div className="col-span-6 sm:col-span-3">
                                        <label className="block text-sm font-medium text-gray-700">From Date</label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => {
                                                setFromDate(startOfDay(date));
                                            }}
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                            dateFormat="d MMMM yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label className="block text-sm font-medium text-gray-700">To Date</label>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => {
                                                setToDate(endOfDay(date));
                                            }}
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                            dateFormat="d MMMM yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                </div>

                                <div className="space-x-4">
                                    <SubmitButton
                                        type="button"
                                        onClick={() => fetchReportData(values)}
                                        disabled={!isValid || isSubmitting || !fromDate || !toDate}
                                        isSubmitting={loading}
                                        text="View Report"
                                    />

                                    <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !fromDate || !toDate} isSubmitting={isSubmitting} text="Download Report" />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

                {loading && <div className="mt-4">Loading report data...</div>}
                {reportData && <ReportTable data={reportData} />}
            </div>
        </>
    );
}
