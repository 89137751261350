import React, {useEffect, useState, Fragment, useRef} from "react";
import axios from "axios";
import {Dialog, Transition} from "@headlessui/react";
import {Formik, Form, Field, FieldArray} from "formik";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import SettingsTextArea from "../../components/Forms/SettingsTextarea";
import SubmitButton from "../../components/Forms/SubmitButton";
import {faSave} from "@fortawesome/pro-solid-svg-icons";
import {Link} from "react-router-dom";
import moment from "moment";

export default function WhatsappTemplatesPage() {
	const [templates, setTemplates] = useState([]);
	const [template, setTemplate] = useState({});
	const [loading, setLoading] = useState(true);
	const [openPopup, setOpenPopup] = useState(0);
	const cancelButtonRef = useRef(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredTemplates, setFilteredTemplates] = useState([]);
	const [messagebird_status, setMessagebird_status] = useState(null);

	useEffect(() => {
		axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}whatsapp_templates_all`,
		})
			.then((response) => {
				setTemplates(response.data);
				setLoading(false);
			})
			.catch((error) => {
				alert("Failed to get templates");
				setLoading(false);
			});
	}, []);

	// Filter templates based on the search term
	useEffect(() => {
		const lowerCaseSearchTerm = searchTerm.toLowerCase();
		const filtered = templates.filter((template) => template.title.toLowerCase().includes(lowerCaseSearchTerm) || template.message.toLowerCase().includes(lowerCaseSearchTerm));
		setFilteredTemplates(filtered);
	}, [searchTerm, templates]);

	async function editTemplate(id, popup) {
		const selectedTemplate = templates.find((template) => template.id === id);

		if (id > 0 && selectedTemplate) {
			let parsedComponents = null;

			// Parse the components, ensuring it's an array
			if (selectedTemplate.header_type === 3) {
				parsedComponents = selectedTemplate.components;

				// Handle double-encoded JSON
				if (typeof parsedComponents === "string") {
					try {
						parsedComponents = JSON.parse(parsedComponents); // First parse
						if (typeof parsedComponents === "string") {
							parsedComponents = JSON.parse(parsedComponents); // Second parse if still a string
						}
					} catch (error) {
						console.error("Error parsing components:", error);
					}
				}

				console.log("Parsed Components after JSON parse:", parsedComponents);

				// Ensure parsedComponents is an array and restructure if necessary
				if (!Array.isArray(parsedComponents)) {
					parsedComponents = [
						{type: "BODY", text: ""},
						{type: "CAROUSEL", cards: [{header_url: "", body_text: "", buttons: []}]},
					];
				} else {
					// Process the CAROUSEL to ensure each card is in a form-friendly structure
					parsedComponents = parsedComponents.map((component) => {
						if (component.type === "CAROUSEL") {
							return {
								...component,
								cards: component.cards.map((card) => ({
									header_url: card.components?.find((comp) => comp.type === "HEADER")?.example?.header_url[0] || "",
									body_text: card.components?.find((comp) => comp.type === "BODY")?.text || "",
									buttons: card.components?.find((comp) => comp.type === "BUTTONS")?.buttons || [],
								})),
							};
						}
						return component;
					});
				}

				console.log("Parsed Components after restructuring:", parsedComponents);
			}

			setMessagebird_status(false);

			// Get MB status
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}mb_status/${selectedTemplate.id}`,
			})
				.then((response) => {
					setMessagebird_status(response.data);
				})
				.catch((error) => {
					console.log("Error getting MB status:", error);
				});

			// Update the template with parsed components
			setTemplate({
				...selectedTemplate,
				components: parsedComponents,
			});

			setOpenPopup(1);
		} else {
			setTemplate({
				id: 0,
				title: "",
				message: "",
				approved: 1,
			});

			setOpenPopup(popup);
		}
	}

	async function deleteTemplate(id) {
		if (window.confirm("Are you sure?")) {
			setLoading(true);
			await axios({
				method: "delete",
				url: `${process.env.REACT_APP_API_URL}delete_whatsapp_template/${id}`,
			})
				.then((response) => {
					setLoading(true);
					axios({
						method: "get",
						url: `${process.env.REACT_APP_API_URL}whatsapp_templates_all`,
					})
						.then((response) => {
							setTemplates(response.data);
							setLoading(false);
						})
						.catch((error) => {
							alert("Failed to get templates");
							setLoading(false);
						});
				})
				.catch((error) => {
					alert("Failed to delete template");
					setLoading(false);
				});
		}
	}

	async function do_send_mb(template_id, action) {
		setLoading(true);
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}send_whatsapp_template_mb/${template_id}/${action}`,
		})
			.then((response) => {
				setLoading(true);
				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}whatsapp_templates_all`,
				})
					.then((response) => {
						setTemplates(response.data);

						editTemplate(template.id, 1);

						// setTemplate(response.data.find((template) => template.id === template_id));
						setLoading(false);
					})
					.catch((error) => {
						alert("Failed to get templates");
						setLoading(false);
					});
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	}

	return (
		<div className="container p-5 mx-auto">
			<div className="flex justify-between items-center">
				<h1 className="font-bold mb-5">Whatsapp Templates</h1>

				<div className="flex space-x-4">
					<button className="bg-primary text-white px-4 py-1 rounded-full" onClick={() => editTemplate(0, 1)}>
						Add Template
					</button>

					<Link to="/settings_new" className="bg-primary text-white px-4 py-2 rounded-full">
						Back to Settings
					</Link>
				</div>
			</div>

			<div className="mb-4">
				<input type="text" placeholder="Search templates..." className="w-full px-4 py-2 border rounded-lg"
					   value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
			</div>

			{loading === true ? (
				<p>Loading...</p>
			) : (
				<table className="min-w-full divide-y divide-gray-300">
					<thead className="bg-gray-50">
					<tr>
						<th scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
							Title
						</th>
						<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
							Message
						</th>
						<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
							Recipient
						</th>
						<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
							Type
						</th>
						<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
							Created<br/>
							Updated
						</th>
						<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
							Actions
						</th>
					</tr>
					</thead>
					<tbody className="divide-y divide-gray-200">
					{filteredTemplates.length > 0 ? (
						filteredTemplates.map((template) => (
							<tr key={template.id}>
								<td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
									{template.title}
									<br/>
									{template.approved == 1 ? "Approved" : "Disabled"}
								</td>
								<td className="px-3 py-4 text-sm text-gray-500">{template.message}</td>
								<td className="px-3 py-4 text-sm text-gray-500">{template.recipient}</td>
								<td className="px-3 py-4 text-sm text-gray-500">{template.header_type === 3 ? "Carousel" : "Normal"}</td>
								<td className="px-3 py-4 text-sm text-gray-500 w-36">
									{template.created_at && (
										<p>
											{moment(template.created_at).format("YYYY-MM-DD HH:mm")}
										</p>
									)}
									{template.updated_at && (
										<p>
											{moment(template.updated_at).format("YYYY-MM-DD HH:mm")}
										</p>
									)}
								</td>
								<td className="px-3 py-4 text-sm text-gray-500 space-y-2">
									<button className="bg-primary text-white px-4 py-1 rounded-full w-full"
											onClick={() => editTemplate(template.id, 1)}>
										EDIT
									</button>
									<button className="bg-primary text-white px-4 py-1 rounded-full w-full"
											onClick={() => deleteTemplate(template.id)}>
										DELETE
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="3" className="text-center py-4 text-gray-500">
								No templates found.
							</td>
						</tr>
					)}
					</tbody>
				</table>
			)}

			<Transition.Root show={openPopup === 1} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {
				}}>
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0"
									  enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100"
									  leaveTo="opacity-0">
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div
							className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel
									className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
									<Formik
										initialValues={template}
										enableReinitialize="true"
										onSubmit={async (values, {setSubmitting, setErrors, resetForm}) => {
											setSubmitting(true);

											const formData = new FormData();
											for (const key in values) {
												if (values.hasOwnProperty(key)) {
													formData.append(key, values[key]);
												}
											}

											// Append file if it exists
											if (values.file) {
												formData.append("file", values.file);
											}


											if (values.header_type == 3) {
												const carouselData = {
													components: [
														{
															type: "BODY",
															text: values.message,
														},
														{
															type: "CAROUSEL",
															cards:
																values.components[1]?.cards.map((card) => ({
																	components: [
																		{
																			type: "HEADER",
																			format: "IMAGE",
																			example: {
																				header_url: [card.header_url],
																			},
																		},
																		{
																			type: "BODY",
																			text: card.body_text,
																		},
																		{
																			type: "BUTTONS",
																			buttons: card.buttons.map((button) => ({
																				type: button.type,
																				text: button.text,
																				...(button.type === "URL" && {
																					url: button.url,
																					example: [button.example]
																				}),
																			})),
																		},
																	],
																})) || [],
														},
													],
												};

												formData.append("components", JSON.stringify(carouselData.components));
											}

											await axios({
												method: "post",
												url: `${process.env.REACT_APP_API_URL}store_whatsapp_template`,
												data: formData,
											})
												.then((resp) => {
													setLoading(true);
													axios({
														method: "get",
														url: `${process.env.REACT_APP_API_URL}whatsapp_templates_all`,
													})
														.then((response) => {
															setTemplates(response.data);
															setLoading(false);
														})
														.catch((error) => {
															alert("Failed to get templates");
															setLoading(false);
														});
												})
												.catch((error) => {
													console.log(error);
													console.log(error.response);
												})
												.finally(() => {
													setSubmitting(false);
													setOpenPopup(false);
												});
										}}
									>
										{({
											  values,
											  touched,
											  errors,
											  setFieldValue,
											  setFieldTouched,
											  handleChange,
											  handleBlur,
											  handleSubmit,
											  isSubmitting,
											  isValid,
											  dirty
										  }) => (
											<Form onSubmit={handleSubmit}
												  className="space-y-8 divide-y divide-gray-200 m-4">
												<div className="py-6 px-4 sm:p-6 xl:pb-8">
													<div className="space-y-4">
														<div className="grid grid-cols-2 gap-4">
															<div>
																<Field name="title" type="text" as={SettingsTextInput}
																	   label="Title"/>
															</div>

															<div>
																<SettingsSelect
																	label="Status"
																	name="approved"
																	onChange={setFieldValue}
																	onBlur={setFieldTouched}
																	value={values.approved}
																	touched={touched.approved}
																	error={errors.approved}
																	options={[
																		{value: 1, label: "Active"},
																		{value: 0, label: "Disabled"},
																	]}
																/>
															</div>

															<div className="col-span-2">
																<SettingsTextArea
																	name="message"
																	placeholder="Message"
																	errors={errors.message}
																	emojis={true}
																	setFieldValue={setFieldValue}
																	handleChange={handleChange}
																	value={values.message}
																	wysiwyg={false}
																/>
															</div>

															<div className="col-span-2">
																<SettingsSelect
																	label="Recipient"
																	name="recipient"
																	onChange={setFieldValue}
																	onBlur={setFieldTouched}
																	value={values.recipient}
																	touched={touched.recipient}
																	error={errors.recipient}
																	options={[
																		{value: 'Main Chat', label: 'Main Chat'},
																		{
																			value: 'Responsible for Funding',
																			label: 'Responsible for Funding'
																		},

																		{value: 'Student', label: 'Student'},
																		{value: 'Parent', label: 'Parent'},
																		{value: 'Sponsor', label: 'Sponsor'},
																		{value: 'Room Mate', label: 'Room Mate'},

																		{
																			value: 'Main Applicant',
																			label: 'Main Applicant'
																		},
																		{value: 'Co-Applicant', label: 'Co-Applicant'},
																		{value: 'Lease Holder', label: 'Lease Holder'},
																		{value: 'Tenant', label: 'Tenant'},

																		{value: 'Other', label: 'Other'},
																	]}
																/>
															</div>

															<div>
																<SettingsSelect
																	label="Header Type"
																	name="header_type"
																	onChange={setFieldValue}
																	onBlur={setFieldTouched}
																	value={values.header_type}
																	touched={touched.header_type}
																	error={errors.header_type}
																	options={[
																		{value: 0, label: "None"},
																		{value: 1, label: "Image"},
																		{value: 2, label: "Document"},
																		{value: 3, label: "Carousel"},
																		{value: 4, label: "Booking Summary"},
																		{value: 5, label: "Lease"},
																	]}
																/>
															</div>

															{(values.header_type > 0 && values.header_type < 3) && (
																<div>
																	{values.id > 0 ? (
																		<div className="mt-4">
																			<label htmlFor="file_id"
																				   className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">
																				Upload a File
																			</label>
																			{values.file_path ? (
																				<>
																					{values.header_type == 1 && <img
																						src={process.env.REACT_APP_LARAVEL_URL + "storage/" + values.file_path}/>}
																					{values.header_type == 2 && (
																						<a href={process.env.REACT_APP_LARAVEL_URL + "storage/" + values.file_path}
																						   target="_blank">
																							Download PDF
																						</a>
																					)}
																				</>
																			) : (
																				<input
																					id="file"
																					name="file"
																					type="file"
																					onChange={(event) => {
																						setFieldValue("file", event.currentTarget.files[0]);
																					}}
																				/>
																			)}
																		</div>
																	) : (
																		<div className="mt-4">File upload only works
																			after saving</div>
																	)}
																</div>
															)}
														</div>

														{values.header_type === 3 && (
															<FieldArray name="components[1].cards">
																{({remove, push}) => (
																	<div>
																		{values.components?.[1].cards.map((card, index) => (
																			<div key={index}
																				 className="border p-4 my-4 rounded">
																				<label>Image</label>
																				{values.components[1].cards[index].header_url ? (
																					<div
																						className="mt-4 flex justify-between items-center">
																						{/* Display the uploaded file */}
																						{values.header_type === 1 ? (
																							<img
																								src={values.components[1].cards[index].header_url}
																								alt="Uploaded Header"
																								className="w-full h-auto mb-2 rounded-lg"
																							/>
																						) : (
																							<img
																								src={values.components[1].cards[index].header_url}
																								alt="Uploaded Header"
																								className="w-96 h-auto mb-2 rounded-lg"/>
																						)}

																						{/* Delete button to remove the uploaded file */}
																						<button
																							type="button"
																							onClick={() => {
																								setFieldValue(`components[1].cards[${index}].header_url`, "");
																								setFieldValue(`components[1].cards[${index}].file_id`, null);

																								// Optionally, you can add API call to delete the file from the server here
																								axios.post(`${process.env.REACT_APP_API_URL}delete_external_file`, {
																									file_id: values.components[1].cards[index].file_id,
																								});
																							}}
																							className="bg-red-500 text-white px-4 py-1 rounded-full mt-2"
																						>
																							Delete File
																						</button>
																					</div>
																				) : (
																					// Show file upload input when no file is uploaded
																					<input
																						type="file"
																						onChange={(event) => {
																							const file = event.currentTarget.files[0];
																							if (file) {
																								const formData = new FormData();
																								formData.append("file", file);

																								axios
																									.post(`${process.env.REACT_APP_API_URL}upload_external_file`, formData, {
																										headers: {
																											"Content-Type": "multipart/form-data",
																										},
																									})
																									.then((response) => {
																										const {
																											file_id,
																											path
																										} = response.data;
																										// Set the file path and file ID in your form
																										setFieldValue(`components[1].cards[${index}].header_url`, path);
																										setFieldValue(`components[1].cards[${index}].file_id`, file_id);
																									})
																									.catch((error) => {
																										console.error("File upload failed:", error);
																									});
																							}
																						}}
																						className="w-full p-2 border rounded"
																					/>
																				)}

																				<SettingsTextArea
																					name={`components[1].cards[${index}].body_text`}
																					placeholder="Card Text"
																					errors={errors.components?.[1].cards?.[index]?.body_text}
																					emojis={true}
																					setFieldValue={setFieldValue}
																					handleChange={handleChange}
																					value={values.components?.[1].cards?.[index]?.body_text}
																					wysiwyg={false}
																				/>

																				<FieldArray
																					name={`components[1].cards[${index}].buttons`}>
																					{({
																						  remove: removeButton,
																						  push: pushButton
																					  }) => (
																						<div>
																							{card.buttons.map((button, btnIndex) => (
																								<div key={btnIndex}
																									 className="flex gap-2 items-center mt-2">
																									<Field
																										name={`components[1].cards[${index}].buttons[${btnIndex}].text`}
																										placeholder="Button Text"
																										className="p-2 border rounded"
																									/>
																									<Field
																										name={`components[1].cards[${index}].buttons[${btnIndex}].type`}
																										as="select"
																										className="p-2 border rounded"
																									>
																										<option
																											value="QUICK_REPLY">Quick
																											Reply
																										</option>
																										<option
																											value="URL">URL
																										</option>
																									</Field>
																									{button.type === "URL" && (
																										<Field
																											name={`components[1].cards[${index}].buttons[${btnIndex}].url`}
																											placeholder="URL"
																											className="p-2 border rounded"
																										/>
																									)}
																									{values.components[1].cards[index].buttons[btnIndex].text.length}
																									<button
																										type="button"
																										onClick={() => {
																											if (window.confirm("Are you sure you want to remove this card?")) {
																												removeButton(btnIndex);
																											}
																										}}
																										className="text-red-500 ml-2"
																									>
																										Remove Button
																									</button>
																								</div>
																							))}

																							{card.buttons.length < 3 && (
																								<div
																									className="flex gap-2 mt-4">
																									<button
																										type="button"
																										onClick={() => pushButton({
																											type: "QUICK_REPLY",
																											text: ""
																										})}
																										className="bg-primary text-white px-4 py-1 rounded-full"
																									>
																										Add Quick Reply
																										Button
																									</button>
																									<button
																										type="button"
																										onClick={() => pushButton({
																											type: "URL",
																											text: "",
																											url: ""
																										})}
																										className="bg-primary text-white px-4 py-1 rounded-full"
																									>
																										Add URL Button
																									</button>
																								</div>
																							)}
																						</div>
																					)}
																				</FieldArray>
																				<button
																					type="button"
																					onClick={() => {
																						if (window.confirm("Are you sure you want to remove this card?")) {
																							remove(index);
																						}
																					}}
																					className="mt-2 text-red-500"
																				>
																					Remove Card
																				</button>
																			</div>
																		))}
																		<button type="button" onClick={() => push({
																			header_url: "",
																			body_text: "",
																			buttons: []
																		})} className="mt-4 text-blue-500">
																			Add New Card
																		</button>
																	</div>
																)}
															</FieldArray>
														)}


													</div>

													<div
														className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
														<SubmitButton
															name="Save"
															disabled={!isValid || isSubmitting || !dirty}
															isSubmitting={isSubmitting}
															icon={faSave}
															text="Save"
															style={{
																width: "100%",
															}}
														/>

														<button
															type="button"
															className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
															onClick={() => setOpenPopup(false)}
															ref={cancelButtonRef}
														>
															CLOSE
														</button>
													</div>

													{values.header_type === 3 && (
														<div className="mt-4">
															{template?.id > 0 && (
																<>
																	<div className="flex gap-2">
																		<button
																			type="button"
																			className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
																			onClick={() => do_send_mb(template.id, "new")}
																		>
																			{loading === true ? "PLEASE WAIT" : "NEW SEND TO MB"}
																		</button>
																		<button
																			type="button"
																			className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
																			onClick={() => do_send_mb(template.id, "update")}
																		>
																			{loading === true ? "PLEASE WAIT" : "UPDATE SEND TO MB"}
																		</button>
																	</div>
																	<p>Please remember to SAVE first.</p>
																</>
															)}
														</div>
													)}

													{template?.id > 0 && (
														<>
															{template?.messagebird_response !== null && (
																<div className="overflow-auto mt-8">
																	<p className="text-sm font-medium text-gray-700">Last
																		MessageBird Response:</p>
																	<p className="text-sm text-gray-500">{template?.messagebird_response}</p>
																</div>
															)}

															{messagebird_status !== false ? (
																<div className="overflow-auto mt-8">
																	{messagebird_status?.errors ? (
																		<>
																			<p className="text-sm font-medium text-gray-700">Current
																				MessageBird
																				Error:</p>
																			<p className="text-sm text-gray-500">{messagebird_status?.errors?.[0]?.description}</p>
																		</>
																	) : (
																		<>
																			<p className="text-sm font-medium text-gray-700">Current
																				MessageBird
																				Status:</p>
																			<p className="text-sm text-gray-500">{messagebird_status?.[0]?.status}</p>
																		</>
																	)}
																</div>
															) : (
																<div className="overflow-auto mt-8"><p
																	className="text-sm text-gray-500">Loading
																	MessageBird Status...</p></div>
															)}
														</>
													)}
												</div>
											</Form>
										)}
									</Formik>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
}
