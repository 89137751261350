import React, { Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import { getBooking } from "../../store/booking/bookingActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ModalAssign(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { tags } = useSelector((state) => state.tags);
  const { current_booking } = useSelector((state) => state.booking);
  const [locations, setLocations] = useState([]);
  const [allowFolders, setAllowFolders] = useState(true);
  const [currentLocation, setCurrentLocation] = useState("");

  let currentTag = current_booking?.tag;
  if (!current_booking?.tag || current_booking?.tag === "") {
    currentTag = "";
  }

  if (allowFolders === true) {
    if (props.type !== "collections" && props.type !== "renewals") {
      getFolders();
    } else if (props.type == "collections") {
      getCollections();
    } else {
      getRenewals();
    }
  }

  async function getCollections() {
    setAllowFolders(false);
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}collections`,
    }).then((response) => {
      console.log(response.data.collections);
      let allCollections = response.data.collections;
      let locationList = [{ id: 0, name: "Default" }];
      Object.keys(allCollections).forEach((key) => {
        if (allCollections[key].editable === 1) {
          locationList.push({
            id: allCollections[key].id,
            name: allCollections[key].name,
          });
        }

        if (
          props.type === "collections" &&
          allCollections[key].id === current_booking.collections_tag
        ) {
          setCurrentLocation(allCollections[key].name);
        }
      });

      setLocations(locationList);
    });
  }

  async function getRenewals() {
    setAllowFolders(false);
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}renewals`,
    }).then((response) => {
      console.log(response.data.renewals);
      let allRenewals = response.data.renewals;
      let locationList = [];
      Object.keys(allRenewals).forEach((key) => {
        if (allRenewals[key].editable === 1) {
          locationList.push({
            id: allRenewals[key].id,
            name: allRenewals[key].name,
          });
        }
        if (
          props.type === "renewals" &&
          allRenewals[key].id === current_booking.renewals_tag
        ) {
          setCurrentLocation(allRenewals[key].name);
        }
      });

      setLocations(locationList);
    });
  }

  async function getFolders() {
    setAllowFolders(false);
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}folders`,
    }).then((response) => {
      // console.log(response.data.folders);
      let allFolders = response.data.folders;
      let locationList = [];

      Object.keys(allFolders).forEach((key) => {
        if (
          allFolders[key].name !== "All" &&
          allFolders[key].name !== "Declined" &&
          allFolders[key].name !== "Cancelled" &&
          allFolders[key].name !== "Archived"
        ) {
          if (allFolders[key].name == "All Residents") {
            allFolders[key].name = "Residents";
          }

          locationList.push({
            id: allFolders[key].id,
            name: allFolders[key].name,
          });

          if (
            props.type !== "collections" &&
            props.type !== "renewals" &&
            allFolders[key].name === current_booking.location
          ) {
            setCurrentLocation(
              allFolders[key].name == "General"
                ? "General"
                : allFolders[key].name
            );
          }
        }
      });

      setLocations(locationList);
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>

                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    ></Dialog.Title>

                    <Formik
                      initialValues={{
                        location: currentLocation ?? "New Leads",
                        tag: currentTag,
                      }}
                      enableReinitialize="true"
                      validate={(values) => {
                        const errors = {};
                        if (!values.location) {
                          errors.location = "Please select a location";
                        }
                        return errors;
                      }}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setSubmitting(true);

                        let submitData = {
                          location: values.location,
                          tag: values.tag,
                          currentLocation: props.currentLocation,
                          type: props.type,
                        };

                        await axios({
                          method: "put",
                          url: `${process.env.REACT_APP_API_URL}assign/${props.id}`,
                          data: submitData,
                        })
                          .then(() => {
                            const title = "Booking Moved";
                            const message =
                              "Booking moved to " + values.location;

                            toast(
                              <NotificationToast
                                title={title}
                                message={message}
                              />
                            );
                          })
                          .catch((error) => {
                            console.log(error);
                            console.log(error.response);
                          })
                          .finally(() => {
                            setSubmitting(false);
                            resetForm();
                            dispatch(getBooking());

                            dispatch(closeModal());
                          });
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 m-4 w-full "
                        >
                          <h2>Location:</h2>

                          <div>
                            <Field
                              as="select"
                              name="location"
                              className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md focus:outline-none transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            >
                              {!currentLocation && (
                                <option value="">Orphaned</option>
                              )}

                              {locations.map((location) => (
                                <option
                                  key={location.id}
                                  value={location.name}
                                  className={classNames(
                                    location.name === current_booking?.location
                                      ? "text-white bg-primary"
                                      : "text-black"
                                  )}
                                >
                                  {location.name}
                                </option>
                              ))}
                            </Field>
                            <p className="text-red-400 text-sm">
                              {values.errors}
                            </p>
                            {console.log(values.errors)}

                            {(values.location === "Residents" ||
                              values.location === "General") &&
                              props.type !== "collections" &&
                              props.type !== "renewals" && (
                                <Field
                                  as="select"
                                  name="tag"
                                  className="capitalize cursor-pointer relative p-2 mx-2 bg-light_background rounded-md transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                >
                                  {values.location === "Residents" && (
                                    <option value="">No Tag</option>
                                  )}

                                  {tags
                                    .filter(
                                      (tag) =>
                                        tag.parent_folder === values.location ||
                                        (tag.parent_folder === "General" &&
                                          values.location == "General")
                                    )
                                    .map((tag) => (
                                      <option
                                        key={tag.id}
                                        value={tag.id}
                                        className={classNames(
                                          tag.id === currentTag
                                            ? "text-white bg-primary"
                                            : "text-black"
                                        )}
                                      >
                                        {tag.name}
                                      </option>
                                    ))}
                                </Field>
                              )}
                          </div>

                          {/* <h2>Assigned To:</h2>

                          <div>
                            {staff.map((person) => (
                              <div key={person.id}>
                                <div className="flex justify-between">
                                  <div className="flex align-middle">
                                    <ProfilePicture id={person.id} />
                                    <span className="font-normal ml-3 block truncate">
                                      {person.name + " " + person.surname}
                                    </span>
                                  </div>

                                  <Field
                                    type="checkbox"
                                    name="assigned_to"
                                    value={person.id}
                                    key={person.id}
                                    checked={values?.assigned_to?.includes(
                                      `${person.id}`
                                    )}
                                  />
                                </div>
                              </div>
                            ))}
                          </div> */}

                          <SubmitButton
                            name="Save"
                            disabled={!isValid || isSubmitting || !dirty}
                            isSubmitting={isSubmitting}
                            text="Assign"
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
