import React, { useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function ReportChatAudit() {
    const [loading, setLoading] = useState(false);
    const [auditLogs, setAuditLogs] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const renderRegularTable = (values, data) => {
        // Early return if no data
        if (!data || !Array.isArray(data) || data.length === 0) {
            return <p>No data available</p>;
        }

        return (
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="text-left px-4 py-2 border">Date</th>
                            {values.audit_type === "new_chats_day" ? (
                                <th className="text-left px-4 py-2 border">New Chats</th>
                            ) : (
                                <>
                                    <th className="text-left px-4 py-2 border">User</th>
                                    <th className="text-left px-4 py-2 border">Messages Sent</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                                <td className="px-4 py-2 border">{values.audit_type === "new_chats_day" ? moment(row.dt).format("DD MMM YYYY") : moment(row.dt, "YYYY-MM").format("MMMM YYYY")}</td>

                                {values.audit_type === "new_chats_day" ? (
                                    <td className="px-4 py-2 border">{row.total_bookings}</td>
                                ) : (
                                    <>
                                        <td className="px-4 py-2 border">{`${row.name} ${row.surname}`}</td>
                                        <td className="px-4 py-2 border">{row.messages_sent}</td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderFolderTable = (data) => {
        const metadata = data._metadata || null;

        // Get all unique dates from all folders
        const allDates = new Set();
        const folders = [];

        // Extract folder names and all dates
        Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && !["unique_bookings", "_metadata", "orphaned_logs", "deleted_bookings"].includes(key)) {
                folders.push(key);
                Object.keys(value).forEach((date) => allDates.add(date));
            }
        });

        // Sort dates
        const sortedDates = Array.from(allDates).sort();

        return (
            <div>
                {/* Metadata section */}
                {metadata && (
                    <div className="mb-4 p-4 bg-gray-50 rounded-lg">
                        <h3 className="font-medium mb-2">Report Summary</h3>
                        <div className="grid grid-cols-2 gap-4 text-sm">
                            <div>
                                <p>
                                    Period: {metadata.date_range.start} to {metadata.date_range.end}
                                </p>
                                <p>Total Unique Bookings: {metadata.total_unique_bookings}</p>
                            </div>
                            <div>
                                <p className="text-amber-600">
                                    Orphaned Logs: {metadata.total_orphaned_logs}({metadata.total_unique_orphaned_bookings} unique)
                                </p>
                                <p className="text-red-600">
                                    Deleted Bookings: {metadata.total_deleted_logs}({metadata.total_unique_deleted_bookings} unique)
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {/* Main table */}
                <div className="overflow-x-auto">
                    <table className="w-full min-w-max">
                        <thead>
                            <tr className="bg-gray-50">
                                <th className="text-left px-4 py-2 border">Date</th>
                                {folders.map((folder) => (
                                    <th key={folder} className="text-left px-4 py-2 border">
                                        {folder}
                                    </th>
                                ))}
                                <th className="text-left px-4 py-2 border bg-gray-100">Folder Total</th>
                                <th className="text-left px-4 py-2 border bg-blue-50">Unique Bookings</th>
                                <th className="text-left px-4 py-2 border bg-amber-50">Orphaned</th>
                                <th className="text-left px-4 py-2 border bg-red-50">Deleted</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedDates.map((date) => {
                                const folderTotal = folders.reduce((total, folder) => {
                                    return total + (data[folder]?.[date] || 0);
                                }, 0);

                                return (
                                    <tr key={date} className="hover:bg-gray-50">
                                        <td className="px-4 py-2 border font-medium">{moment(date).format("DD MMM YYYY")}</td>
                                        {folders.map((folder) => (
                                            <td key={`${date}-${folder}`} className="px-4 py-2 border text-center">
                                                {data[folder]?.[date] || 0}
                                            </td>
                                        ))}
                                        <td className="px-4 py-2 border text-center font-medium bg-gray-50">{folderTotal}</td>
                                        <td className="px-4 py-2 border text-center font-medium bg-blue-50">{data.unique_bookings?.[date] || 0}</td>
                                        <td className="px-4 py-2 border text-center font-medium bg-amber-50">
                                            <div>
                                                {data.orphaned_logs?.[date]?.total_count || 0}
                                                {data.orphaned_logs?.[date]?.unique_count > 0 && <div className="text-xs text-amber-600">({data.orphaned_logs[date].unique_count} unique)</div>}
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 border text-center font-medium bg-red-50">
                                            <div>
                                                {data.deleted_bookings?.[date]?.total_count || 0}
                                                {data.deleted_bookings?.[date]?.unique_count > 0 && <div className="text-xs text-red-600">({data.deleted_bookings[date].unique_count} unique)</div>}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                {/* Legend/Notes */}
                <div className="mt-4 p-4 bg-gray-50 rounded-lg text-sm space-y-1">
                    <p>* Folder Total shows the sum of chats across all folders for each day</p>
                    <p>* Unique Bookings shows the actual count of distinct bookings for each day</p>
                    <p className="text-amber-600">* Orphaned Logs are audit entries where the original booking no longer exists</p>
                    <p className="text-red-600">* Deleted Bookings are soft-deleted bookings that still have audit logs</p>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>User Activity</h1>

                <Formik
                    initialValues={{
                        audit_type: "chats_sent",
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        const formattedFromDate = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
                        const formattedToDate = toDate ? moment(toDate).format("YYYY-MM-DD") : null;
                        setLoading(true);

                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}reports/get_user_activity`,
                            data: { audit_type: values.audit_type, fromDate: formattedFromDate, toDate: formattedToDate },
                        })
                            .then((response) => {
                                setAuditLogs(response.data);
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                            .finally(() => {
                                setLoading(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid md:lg:grid-cols-3 gap-12 mt-5 mb-5 ">
                                    <div>
                                        <SettingsSelect
                                            label="What sort of report would you like to run?"
                                            name="audit_type"
                                            onChange={setFieldValue}
                                            value={values.audit_type}
                                            error={errors.audit_type}
                                            options={[
                                                { value: "chats_sent_month", label: "Chats Sent by Month" },
                                                { value: "chats_sent_day", label: "Chats Sent by Day" },
                                                { value: "bookings_updated", label: "Bookings Updated" },
                                                { value: "new_chats_day", label: "New Chats by Day" },
                                                { value: "chats_daily_folder", label: "Chats by Folder Daily" },
                                            ]}
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">From Date</label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(value) => {
                                                setFromDate(value);
                                            }}
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                            dateFormat={"d MMMM yyyy"}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">To Date</label>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(value) => {
                                                setToDate(value);
                                            }}
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                            dateFormat={"d MMMM yyyy"}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                </div>
                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="View Data" />
                            </div>

                            <div className="mt-5">
                                {loading === true ? <p>Loading...</p> : <>{values.audit_type === "chats_daily_folder" ? renderFolderTable(auditLogs) : renderRegularTable(values, auditLogs)}</>}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
