import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AvailableDatePicker = ({ availableDays, blockedDates, onChange }) => {
    const [selectedDate, setSelectedDate] = useState(null);

    const adjustedBlockedDates = blockedDates.map((date) => {
        const adjustedDate = new Date(date);
        adjustedDate.setDate(adjustedDate.getDate() - 1); // Subtract 1 day
        return adjustedDate.toISOString().split("T")[0]; // Format back to 'YYYY-MM-DD'
    });

    // Transform available ranges into an array of valid dates
    const availableDates = availableDays.flatMap((range) => {
        const startDate = new Date(range.start);
        const endDate = new Date(range.end);
        const dates = [];

        // Generate all dates between start and end
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            dates.push(new Date(date));
        }
        return dates;
    });

    // Check if a date is in the available dates array, is not in the past, and is not a Sunday
    const isAvailableDate = (date) => {
        const today = new Date().setHours(0, 0, 0, 0); // Normalize current date
        const normalizedDate = date.setHours(0, 0, 0, 0); // Normalize selected date
        const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, etc.
        const isBlocked = adjustedBlockedDates.includes(date.toISOString().split("T")[0]);

        return (
            normalizedDate >= today && // Ensure the date is not in the past
            dayOfWeek !== 0 && // Exclude Sundays
            !isBlocked &&
            availableDates.some((availableDate) => new Date(availableDate).setHours(0, 0, 0, 0) === normalizedDate)
        );
    };

    return (
        <div>
            <DatePicker
                selected={selectedDate}
                onChange={(date) => {
                    setSelectedDate(date);
                    onChange(date); // Pass selected date to parent
                }}
                filterDate={isAvailableDate}
                inline // Display the calendar inline
            />
        </div>
    );
};

export default AvailableDatePicker;
