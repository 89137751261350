import React, { useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function ChatMovementReport() {
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const calculateCombinedFolderMovements = (properties) => {
        const combined = {};

        properties.forEach((property) => {
            Object.entries(property.folder_movements).forEach(([folder, counts]) => {
                if (!combined[folder]) {
                    combined[folder] = { current: 0, moved_in: 0 };
                }
                combined[folder].current += counts.current || 0;
                combined[folder].moved_in += counts.moved_in || 0;
            });
        });

        return combined;
    };

    const renderCombinedPropertyReport = (properties) => {
        if (!properties || properties.length === 0) return null;

        const combinedFolderMovements = calculateCombinedFolderMovements(properties);
        const folders = Object.keys(combinedFolderMovements).sort();

        const totalUniqueBookings = properties.reduce((sum, item) => sum + item.unique_bookings, 0);
        const totalOrphanedCount = properties.reduce((sum, item) => sum + item.orphaned_count, 0);
        const totalUniqueOrphaned = properties.reduce((sum, item) => sum + item.unique_orphaned_count, 0);
        const totalDeletedCount = properties.reduce((sum, item) => sum + item.deleted_count, 0);
        const totalUniqueDeleted = properties.reduce((sum, item) => sum + item.unique_deleted_count, 0);

        return (
            <div className="mb-8 bg-white rounded-lg shadow p-4">
                <h3 className="text-lg font-medium mb-4">Combined Property Summary</h3>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Folder
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total Current Count
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total Moved In Count
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {folders.map((folder) => (
                                <tr key={folder} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{folder}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{combinedFolderMovements[folder].current}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{combinedFolderMovements[folder].moved_in}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot className="bg-gray-50">
                            <tr>
                                <td colSpan="3" className="px-6 py-4">
                                    <div className="grid grid-cols-2 gap-4 text-sm">
                                        <div>
                                            <p>Total Unique Bookings: {totalUniqueBookings}</p>
                                        </div>
                                        <div>
                                            <p className="text-amber-600">
                                                Total Orphaned: {totalOrphanedCount} ({totalUniqueOrphaned} unique)
                                            </p>
                                            <p className="text-red-600">
                                                Total Deleted: {totalDeletedCount} ({totalUniqueDeleted} unique)
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    };

    const renderPropertyReport = (property) => {
        if (!property || !property.folder_movements) {
            return null;
        }

        const folderData = property.folder_movements;
        const folders = Object.keys(folderData).sort();

        if (folders.length === 0) {
            return null;
        }

        return (
            <div className="mb-8 bg-white rounded-lg shadow p-4">
                <h3 className="text-lg font-medium mb-4">Property: {property.property_name}</h3>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Folder
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Current Count
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Moved In Count
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {folders.map((folder) => (
                                <tr key={folder} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{folder}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{folderData[folder].current}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{folderData[folder].moved_in}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot className="bg-gray-50">
                            <tr>
                                <td colSpan="3" className="px-6 py-4">
                                    <div className="grid grid-cols-2 gap-4 text-sm">
                                        <div>
                                            <p>Unique Bookings: {property.unique_bookings}</p>
                                        </div>
                                        <div>
                                            <p className="text-amber-600">
                                                Orphaned: {property.orphaned_count} ({property.unique_orphaned_count} unique)
                                            </p>
                                            <p className="text-red-600">
                                                Deleted: {property.deleted_count} ({property.unique_deleted_count} unique)
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    };

    const renderReport = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return <p className="text-gray-500 italic">No data available for the selected period.</p>;
        }

        // Group the data by date
        const groupedByDate = data.reduce((acc, item) => {
            const date = moment(item.report_date).format("YYYY-MM-DD");
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});

        return (
            <div className="space-y-8">
                {Object.entries(groupedByDate).map(([date, properties]) => (
                    <div key={date} className="bg-gray-50 p-6 rounded-xl">
                        <h2 className="text-xl font-semibold mb-4">{moment(date).format("DD MMMM YYYY")}</h2>
                        {renderCombinedPropertyReport(properties)}
                        <div className="space-y-6">{properties.map((property) => renderPropertyReport(property))}</div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
            <h1 className="text-2xl font-bold mb-6">Chat Movement Report</h1>

            <Formik
                initialValues={{}}
                onSubmit={async (values, { setSubmitting }) => {
                    const formattedFromDate = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
                    const formattedToDate = toDate ? moment(toDate).format("YYYY-MM-DD") : null;
                    setLoading(true);

                    try {
                        const response = await axios.get(`${process.env.REACT_APP_API_URL}chat-movement-report`, {
                            params: {
                                start_date: formattedFromDate,
                                end_date: formattedToDate,
                            },
                        });
                        setReportData(response.data);
                    } catch (error) {
                        console.error("Error fetching report:", error);
                    } finally {
                        setLoading(false);
                        setSubmitting(false);
                    }
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="bg-white rounded-lg shadow p-6 mb-6">
                            <div className="grid md:grid-cols-2 gap-6 mb-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">From Date</label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={setFromDate}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                                        dateFormat="d MMMM yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">To Date</label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={setToDate}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                                        dateFormat="d MMMM yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                            </div>
                            <SubmitButton name="Submit" disabled={isSubmitting || !fromDate || !toDate} isSubmitting={isSubmitting} text="Generate Report" />
                        </div>

                        <div className="mt-6">
                            {loading ? (
                                <div className="flex justify-center items-center py-8">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                                </div>
                            ) : (
                                renderReport(reportData)
                            )}
                        </div>
                    </Form>
                )}
            </Formik>

            <div className="mt-8 p-4 bg-white rounded-lg shadow text-sm space-y-2">
                <p>* Current Count shows the number of bookings currently in each folder</p>
                <p>* Moved In Count shows how many bookings were moved into each folder during the period</p>
                <p className="text-amber-600">* Orphaned Logs are audit entries where the original booking no longer exists</p>
                <p className="text-red-600">* Deleted Bookings are soft-deleted bookings that still have audit logs</p>
            </div>
        </div>
    );
}
