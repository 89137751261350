import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { useHistory } from "react-router";
import Select from "react-select";

export default function ReportExportAll() {
    const [loading, setLoading] = useState(false);
    const { staff } = useSelector((state) => state.auth);
    const [properties, setProperties] = useState([]);
    const [lookups, setLookups] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [floors, setFloors] = useState([]);

    const [selectedProperty, setSelectedProperty] = useState(0);
    const [selectedGender, setSelectedGender] = useState(0);

    const { current_staff } = useSelector((state) => state.auth);
    const { tags } = useSelector((state) => state.tags);
    const { settings } = useSelector((state) => state.settings);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);
    const [tagOptions, setTagOptions] = useState([]);

    const years = [
        { label: "All", value: 0 },
        { label: "2023", value: 1 },
        { label: "2024", value: 2 },
        { label: "2025", value: 3 },
    ];

    const student_types = [
        // { label: "Choose an option", value: 0 },
        { label: "All", value: 0 },
        { label: "NSFAS UJ", value: 4 },
        { label: "NSFAS WITS", value: 5 },
        // { label: "CJC", value: 6 },
        { label: "NSFAS TVET", value: 1 },
        { label: "Bursary", value: 2 },
        { label: "Private", value: 3 },
    ];

    const openTypes = [
        { label: "All", value: 0 },
        { label: "Yes", value: 1 },
        { label: "No", value: 2 },
    ];

    const folders = [
        // { label: "All", value: 0 },
        { label: "New Leads", value: "New Leads" },
        { label: "Returning Students", value: "Returning Students" },
        { label: "Applications", value: "Applications" },
        { label: "To Pay", value: "To Pay" },
        { label: "Documents", value: "Documents" },
        { label: "Vetting", value: "Vetting" },
        { label: "Approval", value: "Approval" },
        { label: "Lease Sent", value: "Lease Sent" },
        { label: "Lease Signed", value: "Lease Signed" },
        { label: "Bed X", value: "Bed X" },
        { label: "Purple Beds", value: "Purple Beds" },
        { label: "Registration Check", value: "Registration Check" },
        { label: "Move In Booked", value: "Move In Booked" },
        { label: "Moved-In", value: "Moved-In" },
        { label: "Limbo", value: "Limbo" },
        { label: "All Residents", value: "Residents" },
        { label: "Declined", value: "Declined" },
        { label: "Archived", value: "Archived" },
    ];

    const genders = [
        { label: "All", value: 0 },
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
    ];

    useEffect(async () => {
        let tagsList = [];
        Object.keys(tags).forEach((key) => {
            tagsList.push({ label: tags[key].name, value: tags[key].id });
        });

        setTagOptions(tagsList);

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                let responseData = [
                    { id: 0, value: 0, label: "All" },
                    { id: -1, value: -1, label: "No Building" },
                ];
                setProperties(responseData.concat(response.data));
            })
            .catch((error) => {
                alert("properties failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}uc_lookups`,
        })
            .then((response) => {
                setLookups(response.data);
            })
            .catch((error) => {
                alert("lookups failed");
            });

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, []);

    async function getUnitTypes(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            let responseData = [{ id: 0, value: 0, label: "All" }];
            setUnitTypes(responseData.concat(response.data));
        });
    }

    async function getFloors(property_id, thisGender) {
        if (property_id != 0) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}get_property_floors/${property_id}/${thisGender}`,
            })
                .then((response) => {
                    let responseData = [{ id: 0, value: 0, label: "All" }];
                    setFloors(responseData.concat(response.data.floors));
                })
                .catch((error) => {
                    alert("floors failed");
                });
        }
    }

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Export All</h1>

                <Formik
                    enableReinitialize
                    initialValues={{
                        year_id: 0,
                        property_id: 0,
                        institution: 0,
                        student_type: 0,
                        gender: 0,
                        unit_type: 0,
                        folder: [],
                        open: 0,
                        floor: 0,
                        tag: [],
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        // console.log(values);

                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}reports/export_all`,
                            responseType: "blob",
                            data: {
                                year_id: values.year_id,
                                property_id: values.property_id,
                                institution: values.institution,
                                student_type: values.student_type,
                                gender: values.gender,
                                unit_type: values.unit_type,
                                folder: values.folder,
                                open: values.open,
                                floor: values.floor,
                                tag: values.tag,
                            },
                        })
                            .then((response) => {
                                // console.log(response.data);

                                const href = URL.createObjectURL(response.data);

                                const link = document.createElement("a");
                                link.href = href;
                                link.setAttribute("download", "All Students Tenants.xlsx");
                                document.body.appendChild(link);
                                link.click();

                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                {/* <div>field</div> */}

                                <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5 ">
                                    <SettingsSelect label="Year" name="year_id" onChange={setFieldValue} value={values.year_id} options={years} />
                                    <SettingsSelect
                                        label="Property"
                                        name="property_id"
                                        onChange={setFieldValue}
                                        getUnitTypes={getUnitTypes}
                                        getFloors={(value) => {
                                            getFloors(value, selectedGender);
                                            setSelectedProperty(value);
                                        }}
                                        value={values.property_id}
                                        options={properties}
                                    />

                                    <SettingsSelect label="Institution" name="institution" onChange={setFieldValue} value={values.institution} options={lookups ? lookups[4] : null} />

                                    <SettingsSelect label="Student type" name="student_type" onChange={setFieldValue} value={values.student_type} options={student_types} />

                                    <SettingsSelect
                                        label="Gender"
                                        name="gender"
                                        onChange={setFieldValue}
                                        getFloors={(value) => {
                                            getFloors(selectedProperty, value);
                                            setSelectedGender(value);
                                        }}
                                        value={values.gender}
                                        options={genders}
                                    />

                                    <SettingsSelect label="Unit Type" name="unit_type" onChange={setFieldValue} value={values.unit_type} options={unitTypes} />

                                    <SettingsSelect label="Floor" name="floor" onChange={setFieldValue} value={values.floor} options={floors} />

                                    {/* <SettingsSelect
                                        label="Folder"
                                        name="folder"
                                        onChange={setFieldValue}
                                        value={values.folder}
                                        options={folders}
                                    /> */}

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="folder" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                            Folder
                                        </label>
                                        <Select options={folders} name="folder" onChange={(v) => setFieldValue("folder", v)} className="w-full" isMulti closeMenuOnSelect={false} />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="tag" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                            Tags
                                        </label>
                                        <Select options={tagOptions} name="tag" onChange={(v) => setFieldValue("tag", v)} className="w-full" isMulti closeMenuOnSelect={false} />
                                    </div>

                                    <SettingsSelect label="Open" name="open" onChange={setFieldValue} value={values.open} options={openTypes} />
                                </div>

                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="Download Report" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
