import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Tab } from "@headlessui/react";

import { openModal } from "../../store/modal/modalReducer";
import { getTemplates, getTemplateCategories } from "../../store/template/templateActions";

import AppLoader from "../../components/Helpers/AppLoader";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faLoader, faSave } from "@fortawesome/pro-regular-svg-icons";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SettingsAddButton from "../../components/Settings/SettingsAddButton";
import SettingsEditableRow from "../../components/Settings/SettingsEditableRow";
import SubmitButton from "../../components/Forms/SubmitButton";
import { getTags } from "../../store/tag/tagActions";
import { getCollections } from "../../store/collection/collectionActions";
import { getRenewals } from "../../store/renewal/renewalActions";
import { getAppointments } from "../../store/appointments/appointmentActions";
import { getManagers } from "../../store/managers/managersActions";
import ProfilePictureUpload from "../../components/Forms/ProfilePictureUpload";
import ProfilePicture from "../../components/Auth/ProfilePicture";
import { getStaff } from "../../store/auth/authActions";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import SettingsDatePicker from "../../components/Forms/SettingsDatePicker";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import CustomButton from "../../components/Helpers/CustomButton";
import { getSettings, updateSettings, updatedGuests } from "../../store/settings/settingsActions";
import { getFolders } from "../../store/tag/folderActions";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function SettingsPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [profileLoading, setProfileLoading] = useState(false);
    const { templates } = useSelector((state) => state.templates);
    const { template_categories } = useSelector((state) => state.templates);
    const { tags } = useSelector((state) => state.tags);
    const { folders } = useSelector((state) => state.folders);
    const { current_staff } = useSelector((state) => state.auth);
    const [user, setUser] = useState([]);
    const { collections } = useSelector((state) => state.collections);
    const { renewals } = useSelector((state) => state.renewals);
    const { appointments } = useSelector((state) => state.appointments);
    const { managers } = useSelector((state) => state.managers);
    const { settings, years } = useSelector((state) => state.settings);

    // const [userDB, setUserDB] = useState("");
    const [loading, setLoading] = useState(true);
    const [flaggedIDs, setFlaggedIDs] = useState([]);

    const [foldersOrder, setFoldersOrder] = useState(folders?.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)));
    const [tagsOrder, setTagsOrder] = useState(tags?.sort((a, b) => (a.sort_order > b.sort_order ? -1 : 1)));
    const [collectionOrder, setCollectionOrder] = useState(collections?.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)));
    // const [renewalsOrder, setRenewalsOrder] = useState(renewals?.sort((a, b) => (a.sort_order > b.sort_order ? -1 : 1)));
    const [dragId, setDragId] = useState();

    // console.log("collectionOrder", collectionOrder)

    const [offlineFrom, setOfflineFrom] = useState("");
    const [offlineTo, setOfflineTo] = useState("");
    const [offlineStatus, setOfflineStatus] = useState(false);

    const [breakFrom, setBreakFrom] = useState("");
    const [breakTo, setBreakTo] = useState("");

    const [satFrom, setSatFrom] = useState("");
    const [satTo, setSatTo] = useState("");
    const [sunFrom, setSunFrom] = useState("");
    const [sunTo, setSunTo] = useState("");

    const [satSun, setSatSun] = useState(false);
    const [breakStatus, setBreakStatus] = useState(false);
    const [allowClick, setAllowClick] = useState(true);
    const [default_tba, setDefault_tba] = useState([]);
    const [showAddYear, setShowAddYear] = useState(false);
    const [updateYear, setUpdateYear] = useState(false);

    const filterProp = useRef();

    let template_categories_listed;
    const { staff } = useSelector((state) => state.auth);
    const settingsState = useSelector((state) => state.settings);

    console.log("System settings: ", settingsState.settings);

    const ValuesAccessControl = {
        deactivatesBuildingAccessField: settingsState.settings.disable_building_access_days,
        deactivatesWifiField: settingsState.settings.disable_wifi_days,
    };

    const GuestRules = {
        AmountOfFreeGuests: settingsState.settings.num_free_guests,
        AmountOfPaidGuestsField: settingsState.settings.num_paid_guests,
        CostPerGuestField: settingsState.settings.cost_per_paid_guest,
        LengthStayPerGuestField: settingsState.settings.length_of_stay_limit,
    };

    const QlValuesAccessControl = {
        qldeactivatesBuildingAccessField: settingsState.settings.disable_building_access_days,
        qldeactivatesWifiField: settingsState.settings.disable_wifi_days,
    };

    const QlGuestRules = {
        AmountOfBachelorGuests: settingsState.settings.num_bachelor_guests,
        AmountOf1bedroomGuestsField: settingsState.settings.num_1bed_guests,
        AmountOf2bedroomGuestsField: settingsState.settings.num_2bed_guests,
        QlLengthStayPerGuestField: settingsState.settings.length_of_stay_limit,
    };

    if (staff.length > 0) {
        var current_staff_object = staff.find((e) => e.id === current_staff?.id);
    }

    let subNavigation = [{ name: "Your Profile" }];

    if (!current_staff.building_manager) {
        subNavigation.push({ name: "Templates" });
        subNavigation.push({ name: "Categories" });
        subNavigation.push({ name: "Leasing" });
        subNavigation.push({ name: "Tags" });
        // if(window.location.hostname === "localhost"){
        subNavigation.push({ name: "Collections" });
        // subNavigation.push({ name: "Renewals" });
        // }
        subNavigation.push({ name: "Flagged IDs" });
        subNavigation.push({ name: "Offline Messages" });

        if (process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "UC") {
            subNavigation.push({ name: "Move Out Pricing" });
            // if(window.location.hostname === "localhost"){
            // }
        }

        subNavigation.push({ name: "Appointment Types" });
        subNavigation.push({ name: "Managers" });

        subNavigation.push({ name: "System" });
        subNavigation.push({ name: "System Rules" });
        subNavigation.push({ name: "Tenant Actions" });
    }

    useEffect(async () => {
        setFoldersOrder(folders.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)));
        setTagsOrder(tags.sort((a, b) => (a.sort_order > b.sort_order ? -1 : 1)));
        setCollectionOrder(collections.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)));
        // setRenewalsOrder(renewals.sort((a, b) => (a.sort_order > b.sort_order ? -1 : 1)));
    }, [folders, tags, collections]);

    useEffect(async () => {
        dispatch(getTemplates());
        dispatch(getTemplateCategories());
        getUser();

        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_default_tba_pricing`,
        }).then((response) => {
            setDefault_tba(response.data);
        });

        // await axios({
        //     method: "get",
        //     url: `${process.env.REACT_APP_API_URL}get_editable_collections`,
        // }).then((response) => {
        //     setCollection(response.data);
        // });

        // await axios({
        //     method: "get",
        //     url: `${process.env.REACT_APP_API_URL}user/${current_staff?.id}`,
        // }).then((response) => {
        //     setUserDB(response.data);
        // });

        // Fetch the access control values from the database TO-DO !!!
        // axios.get(`${process.env.REACT_APP_API_URL}get_access_control_values`)
        //     .then(response => {
        //         setValuesAccessControl(response.data);
        //     })
        //     .catch(error => {
        //         console.error("There was an error fetching the access control values!", error);
        //     });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}flagged_ids`,
        })
            .then((response) => {
                setFlaggedIDs(response.data);
            })
            .catch((error) => {
                console.log("error getting flagged");
            });

        setLoading(false);

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });
                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                // if (settings && settings.renewals === 1) {
                //     axios({
                //         method: "get",
                //         url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                //     });
                // }

                setAllowClick(false);
            }
        }
    }, [setUser, current_staff?.id]);

    // console.log(flaggedIDs);

    async function getUser() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}user_profile/${current_staff?.id}`,
        }).then((response) => {
            setUser(response.data);
            setLoading(false);
        });

        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}auto_send_times`,
        }).then((response) => {
            if (response.data[0]) {
                if (response.data[0].template_id == "61") {
                    setOfflineFrom(response.data[0].from_time ? response.data[0].from_time.slice(0, -3) : "");
                    setOfflineTo(response.data[0].to_time ? response.data[0].to_time.slice(0, -3) : "");
                    setOfflineStatus(response.data[0].status == 1 ? true : false);
                }
            }

            if (response.data[1]) {
                if (response.data[1].template_id == "62") {
                    setBreakFrom(response.data[1].from_time ? response.data[1].from_time.slice(0, -3) : "");
                    setBreakTo(response.data[1].to_time ? response.data[1].to_time.slice(0, -3) : "");
                    setBreakStatus(response.data[1].status == 1 ? true : false);
                }
            }

            if (response.data[2]) {
                if (response.data[2].template_id == "63") {
                    setSatFrom(response.data[2].sat_from_time ? response.data[2].sat_from_time.slice(0, -3) : "");
                    setSatTo(response.data[2].sat_to_time ? response.data[2].sat_to_time.slice(0, -3) : "");
                    setSunFrom(response.data[2].sun_from_time ? response.data[2].sun_from_time.slice(0, -3) : "");
                    setSunTo(response.data[2].sun_to_time ? response.data[2].sun_to_time.slice(0, -3) : "");
                    setSatSun(response.data[2].status == 1 ? true : false);
                }
            }
        });
    }

    if (templates) {
        template_categories_listed = Object.keys(templates);
    }

    if (loading === true) {
        return <AppLoader />;
    }

    async function deleteProfilePicture(user_id) {
        setProfileLoading(true);
        await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}profile_picture/${user_id}`,
        }).then(() => {
            dispatch(getStaff());
            setProfileLoading(false);
        });
    }

    function deleteFlaggedID(flagged_id) {
        axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}flagged_ids`,
            data: {
                flagged_id: flagged_id,
            },
        }).then(() => {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}flagged_ids`,
            })
                .then((response) => {
                    setFlaggedIDs(response.data);
                })
                .catch((error) => {
                    console.log("error getting flagged");
                });
        });
    }

    async function toggleStatus(template_id, toggle_value) {
        if (
            (template_id == "61" && offlineFrom !== "" && offlineTo != "") ||
            (template_id == "62" && breakFrom !== "" && breakTo !== "") ||
            (template_id == "63" && satFrom !== "" && satTo !== "") ||
            (template_id == "63" && sunFrom !== "" && sunTo !== "")
        ) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}toggle_send`,
                data: { template_id: template_id, toggle_value: toggle_value },
            });

            if (template_id == "61") {
                setOfflineStatus(toggle_value);
            }
            if (template_id == "62") {
                setBreakStatus(toggle_value);
            }
            if (template_id == "63") {
                setSatSun(toggle_value);
            }
        } else {
            alert("Please select times");

            if (template_id == "61") {
                setOfflineStatus(toggle_value === true ? false : true);
            }
            if (template_id == "62") {
                setBreakStatus(toggle_value === true ? false : true);
            }

            if (template_id == "63") {
                setSatSun(toggle_value === true ? false : true);
            }
        }
    }

    async function changeDates(template_id, time_type, time_value) {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}toggle_send_time`,
            data: { template_id: template_id, time_type: time_type, time_value: time_value },
        });

        if (template_id == "61" && time_type == "from_time") {
            setOfflineFrom(time_value);
        }
        if (template_id == "61" && time_type == "to_time") {
            setOfflineTo(time_value);
        }
        if (template_id == "62" && time_type == "from_time") {
            setBreakFrom(time_value);
        }
        if (template_id == "62" && time_type == "to_time") {
            setBreakTo(time_value);
        }

        if (template_id == "63" && time_type == "sat_from_time") {
            setSatFrom(time_value);
        }
        if (template_id == "63" && time_type == "sat_to_time") {
            setSatTo(time_value);
        }
        if (template_id == "63" && time_type == "sun_from_time") {
            setSunFrom(time_value);
        }
        if (template_id == "63" && time_type == "sun_to_time") {
            setSunTo(time_value);
        }
    }

    // async function arrangeDrop(event, id, list){
    //     console.log(event, id)
    // }

    const handleDrag = (ev) => {
        setDragId(parseFloat(ev.currentTarget.id));
    };

    // console.log(tagsOrder)

    const handleDrop = (ev, type) => {
        let dragBox = {};
        let dropBox = {};

        if (type == "tags") {
            dragBox = tagsOrder.find((tag) => tag.id === dragId);
            dropBox = tagsOrder.find((tag) => tag.id === parseFloat(ev.currentTarget.id));
        } else if (type == "collections") {
            dragBox = collectionOrder.find((collection) => collection.id === dragId);
            dropBox = collectionOrder.find((collection) => collection.id === parseFloat(ev.currentTarget.id));
            // } else if (type == "renewals") {
            //     dragBox = renewalsOrder.find((renewal) => renewal.id === dragId);
            //     dropBox = renewalsOrder.find((renewal) => renewal.id === parseFloat(ev.currentTarget.id));
        } else if (type == "folder") {
            dragBox = foldersOrder.find((renewal) => renewal.id === dragId);
            dropBox = foldersOrder.find((renewal) => renewal.id === parseFloat(ev.currentTarget.id));
        }

        const dragBoxOrder = dragBox.sort_order;
        const dropBoxOrder = dropBox.sort_order;

        let mapData = [];

        if (type == "tags") {
            mapData = tagsOrder;
        } else if (type == "collections") {
            mapData = collectionOrder;
            // } else if (type == "renewals") {
            //     mapData = renewalsOrder;
        } else if (type == "folder") {
            mapData = foldersOrder;
        }

        const newBoxState = mapData.map((item) => {
            if (item.id === dragId) {
                item.sort_order = dropBoxOrder;
            }
            if (item.id === parseFloat(ev.currentTarget.id)) {
                item.sort_order = dragBoxOrder;
            }
            return item;
        });

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}update_folders_order`,
            data: { new_sort_order: newBoxState, type: type },
        })
            .then((response) => {
                if (type == "tags") {
                    let sortNewBoxState = newBoxState.sort((a, b) => (a.sort_order > b.sort_order ? -1 : 1));
                    setTagsOrder(sortNewBoxState);
                    dispatch(getTags());

                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}tagsList`,
                    });
                } else if (type == "collections") {
                    let sortNewBoxState = newBoxState.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
                    setCollectionOrder(sortNewBoxState);
                    dispatch(getCollections());

                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}collectionList`,
                    });
                    // } else if (type == "renewals") {
                    //     let sortNewBoxState = newBoxState.sort((a, b) => (a.sort_order > b.sort_order ? -1 : 1));
                    //     setRenewalsOrder(sortNewBoxState);
                    //     dispatch(getRenewals());

                    //     axios({
                    //         method: "get",
                    //         url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    //     });
                } else if (type == "folder") {
                    let sortNewBoxState = newBoxState.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
                    setFoldersOrder(sortNewBoxState);
                    dispatch(getFolders());

                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}channels`,
                    });
                }
            })
            .catch((error) => {
                console.log("error getting new Tags");
            });
    };

    function triggerCron(cronName) {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}trigger_cron`,
            data: {
                action: cronName,
            },
        }).then((response) => {
            if (response.data.success) {
                alert("Cron action triggered successfully");
            } else {
                alert("Cron action failed");
            }
        });
    }

    return (
        <>
            <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen xl:flex w-full">
                <div className="divide-y divide-gray-200 max-h-76 overflow-y-scroll xl:max-h-full xl:grid xl:grid-cols-12 xl:divide-y-0 xl:divide-x">
                    <Tab.Group>
                        <Tab.List className="bg-light_background xl:col-span-2 rounded-3xl w-full xl:w-72 border-0">
                            <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                <h1 className="text-xl leading-6 font-light text-white capitalize">Settings</h1>
                            </div>
                            {subNavigation.map((item) => (
                                <Tab
                                    key={item.name}
                                    className={({ selected }) =>
                                        classNames(
                                            "group w-full flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                            selected ? "border-l-primary bg-gray-100" : "bg-light_background border-l-light_background"
                                        )
                                    }
                                >
                                    <span className="truncate">{item.name}</span>
                                </Tab>
                            ))}
                        </Tab.List>
                        {/* <Tab.Panels className="my-6 xl:my-0 xl:ml-28 2xl:ml-4 rounded-3xl bg-gray-100 xl:col-span-9"> */}
                        <Tab.Panels className="my-6 xl:my-0 rounded-3xl bg-gray-100 xl:col-span-9">
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background ">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Your Profile</h1>
                                </div>
                                <Formik
                                    initialValues={current_staff_object}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        await axios({
                                            method: "post",
                                            url: `${process.env.REACT_APP_API_URL}update_user_profile`,
                                            data: values,
                                        })
                                            .then((response) => {
                                                console.log(response.data);
                                                toast(<NotificationToast title={"Profile Updates"} message="User Profile has been updated" />);
                                            })
                                            .catch((response) => {
                                                toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to your account</p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <Field name="name" type="text" as={SettingsTextInput} label="Name" />
                                                    <Field name="surname" type="text" as={SettingsTextInput} label="Surname" />
                                                    <Field name="email" type="email" as={SettingsTextInput} label="Email" />
                                                    <Field
                                                        name="password"
                                                        type="password"
                                                        as={SettingsTextInput}
                                                        label="Password"
                                                        tooltip="Only enter something here if you want to change your current password"
                                                    />
                                                </div>

                                                <div className="text-sm font-medium mb-2 text-gray-700 mt-4">
                                                    <p>Profile Picture</p>
                                                    <div className="flex items-center space-x-4 mt-4">
                                                        <div className="flex items-center">
                                                            <ProfilePicture id={current_staff?.id} />
                                                            {current_staff_object?.profile_picture && (
                                                                <FontAwesomeIcon
                                                                    className={`${profileLoading ? "animate-spin" : "cursor-pointer"} "h-6 w-6 text-red-400 cursor-pointer"`}
                                                                    icon={profileLoading ? faLoader : faTimes}
                                                                    onClick={() => {
                                                                        deleteProfilePicture(current_staff?.id);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <ProfilePictureUpload />
                                                    </div>
                                                </div>

                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={faSave}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Templates</h1>
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="flex justify-between items-center">
                                        <div className="pr-24">
                                            <p className="mt-1 text-sm text-gray-500">
                                                These templates will show to everyone on the system. More categories can be added by going to Categories {">"} Template Categories
                                            </p>
                                        </div>
                                        <SettingsAddButton
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "SlideoverTemplateForm",
                                                        modal_props: {
                                                            data: {
                                                                type: "Template",
                                                                categories: template_categories,
                                                                link: "template",
                                                            },
                                                            action: "add",
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="py-4">
                                        {template_categories_listed &&
                                            template_categories_listed.map((title) => {
                                                return (
                                                    <div key={title}>
                                                        <div className="pt-4 text-gray-800">
                                                            <p className="font-medium">{title}</p>
                                                        </div>
                                                        {templates[title].length > 0 &&
                                                            templates[title].map((template) => (
                                                                <div key={template.id}>
                                                                    <SettingsEditableRow
                                                                        callback="getTemplates"
                                                                        label={template.title}
                                                                        description={template.message}
                                                                        data_id={template.id}
                                                                        data_title={template.title}
                                                                        data_message_email={template.message_email}
                                                                        data_message_whatsapp={template.message_whatsapp}
                                                                        data_message_sms={template.message_sms}
                                                                        data_category={template.category}
                                                                        data_files={template.files}
                                                                        categories={template_categories}
                                                                        id={template.id}
                                                                        link={"template/" + template.id}
                                                                        type="template"
                                                                        modal="SlideoverTemplateForm"
                                                                    />
                                                                </div>
                                                            ))}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Categories</h1>
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div>
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <h2 className="text-lg leading-6 font-medium text-gray-900">Template Categories</h2>
                                                <p className="mt-1 text-sm text-gray-500">These are the categories that templates can be grouped by.</p>
                                            </div>
                                            <SettingsAddButton
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modal_type: "SlideoverLookupForm",
                                                            modal_props: {
                                                                data: {
                                                                    type: "Template Category",
                                                                    link: "template_category",
                                                                },
                                                                callback: "getTemplateCategories",
                                                                action: "add",
                                                            },
                                                        })
                                                    )
                                                }
                                            />
                                        </div>

                                        <div className="py-4">
                                            {template_categories.length > 0 &&
                                                template_categories.map((category) => {
                                                    {
                                                        // console.log(category);
                                                    }
                                                    return (
                                                        <div key={category.id}>
                                                            <SettingsEditableRow
                                                                callback="getTemplateCategories"
                                                                label={category.name}
                                                                description=""
                                                                id={category.id}
                                                                value={category.name}
                                                                link={"template_category/" + category.id}
                                                                type="template category"
                                                                modal="SlideoverLookupForm"
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Leasing</h1>
                                </div>

                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <h2 className="text-lg leading-6 font-medium text-gray-900">Leasing</h2>
                                            <p className="mt-1 text-sm text-gray-500">These are the folders that Residents can be categorized under.</p>
                                        </div>
                                        <SettingsAddButton
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "SlideoverLookupForm",
                                                        modal_props: {
                                                            data: {
                                                                type: "folder",
                                                                link: "folder",
                                                            },
                                                            callback: "getFolders",
                                                            action: "add",
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="py-4">
                                        {foldersOrder.length > 0 &&
                                            foldersOrder.map((folder) => {
                                                if (
                                                    folder.name !== "All" &&
                                                    folder.name !== "Cancelled" &&
                                                    folder.name !== "Declined" &&
                                                    folder.name !== "Archived" &&
                                                    folder.name !== "All Residents" &&
                                                    folder.name !== "Residents"
                                                )
                                                    return (
                                                        <div
                                                            key={folder.id}
                                                            draggable={true}
                                                            id={folder.id}
                                                            onDragOver={(ev) => ev.preventDefault()}
                                                            onDragStart={handleDrag}
                                                            onDrop={(ev) => handleDrop(ev, "folder")}
                                                        >
                                                            <SettingsEditableRow
                                                                label={folder.name}
                                                                callback={getFolders()}
                                                                description=""
                                                                id={folder.id}
                                                                value={folder.name}
                                                                link={"folder/" + folder.id}
                                                                bookings_count={folder.bookings_count}
                                                                type="folder"
                                                                modal="SlideoverLookupForm"
                                                            />
                                                        </div>
                                                    );
                                            })}
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Tags</h1>
                                </div>

                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <h2 className="text-lg leading-6 font-medium text-gray-900">Tags</h2>
                                            <p className="mt-1 text-sm text-gray-500">These are the tags that Residents can be categorized under.</p>
                                        </div>
                                        <SettingsAddButton
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "SlideoverLookupForm",
                                                        modal_props: {
                                                            data: {
                                                                type: "Tag",
                                                                link: "tag",
                                                            },
                                                            callback: "getTags",
                                                            action: "add",
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="py-4">
                                        {/* {console.log(tagsOrder)} */}
                                        {tagsOrder.length > 0 &&
                                            tagsOrder.map((tag) => {
                                                return (
                                                    <div
                                                        key={tag.id}
                                                        draggable={true}
                                                        id={tag.id}
                                                        onDragOver={(ev) => ev.preventDefault()}
                                                        onDragStart={handleDrag}
                                                        onDrop={(ev) => handleDrop(ev, "tags")}
                                                    >
                                                        <SettingsEditableRow
                                                            label={tag.name + " (" + tag.parent_folder + ")"}
                                                            callback={getTags()}
                                                            description=""
                                                            id={tag.id}
                                                            value={tag.name}
                                                            parent_folder={tag.parent_folder}
                                                            link={"tag/" + tag.id}
                                                            bookings_count={tag.bookings_count}
                                                            type="tag"
                                                            modal="SlideoverLookupForm"
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </Tab.Panel>

                            {/* {window.location.hostname === "localhost" ? ( */}
                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Collections</h1>
                                </div>

                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <h2 className="text-lg leading-6 font-medium text-gray-900">Collection</h2>
                                            <p className="mt-1 text-sm text-gray-500">These are the tags that Collections can be categorized under.</p>
                                        </div>
                                        <SettingsAddButton
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "SlideoverLookupForm",
                                                        modal_props: {
                                                            data: {
                                                                type: "Collection Tag",
                                                                link: "collections",
                                                            },
                                                            callback: "getCollections",
                                                            action: "add",
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="py-4">
                                        {collectionOrder.length > 0 &&
                                            collectionOrder
                                                .sort((a, b) => a.sort_order - b.sort_order)
                                                .map((collection) => {
                                                    return (
                                                        <>
                                                            {collection.editable == 1 ? (
                                                                <div
                                                                    key={collection.id}
                                                                    draggable={true}
                                                                    id={collection.id}
                                                                    onDragOver={(ev) => ev.preventDefault()}
                                                                    onDragStart={handleDrag}
                                                                    onDrop={(ev) => handleDrop(ev, "collections")}
                                                                >
                                                                    <SettingsEditableRow
                                                                        label={collection.name}
                                                                        callback={getCollections()}
                                                                        description=""
                                                                        id={collection.id}
                                                                        value={collection.name}
                                                                        link={"collections/" + collection.id}
                                                                        bookings_count={collection.bookings_count}
                                                                        type="Collection Tag"
                                                                        modal="SlideoverLookupForm"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    );
                                                })}
                                    </div>
                                </div>
                            </Tab.Panel>
                            {/* ) : "" } */}

                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Flagged ID's</h1>
                                </div>

                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <Formik
                                        initialValues={{ flagged_id: "" }}
                                        enableReinitialize="true"
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}flagged_ids`,
                                                data: values,
                                            })
                                                .then((response) => {
                                                    console.log(response.data);
                                                    toast(<NotificationToast title={"Flagged ID"} message="Flagged ID Added" />);

                                                    axios({
                                                        method: "get",
                                                        url: `${process.env.REACT_APP_API_URL}flagged_ids`,
                                                    })
                                                        .then((response) => {
                                                            setFlaggedIDs(response.data);
                                                        })
                                                        .catch((error) => {
                                                            console.log("error getting flagged");
                                                        });
                                                })
                                                .catch((response) => {
                                                    toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                <div className="py-6 px-4 sm:p-6 xl:pb-8 max-w-sm">
                                                    <p className="mt-1 text-sm text-gray-500">Add a new ID to be flagged</p>

                                                    <div className="mt-6">
                                                        <Field name="flagged_id" type="text" as={SettingsTextInput} label="Flagged ID" />
                                                    </div>

                                                    <div className="mt-8">
                                                        <SubmitButton name="Add Flag" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} icon={faSave} text="Add Flag" />
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                    <div>
                                        {flaggedIDs?.map((f) => (
                                            <div className="flex space-x-3 items-center">
                                                <span className="w-40">{f}</span>

                                                <svg
                                                    onClick={() => deleteFlaggedID(f)}
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="far"
                                                    data-icon="trash-can"
                                                    class="svg-inline--fa fa-trash-can "
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z"
                                                    ></path>
                                                </svg>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Offline Messages</h1>
                                </div>

                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="flex justify-between items-center mb-5">
                                        <div>
                                            <h2 className="text-lg leading-6 font-medium text-gray-900">Offline Messages</h2>
                                            <p className="mt-1 text-sm text-gray-500">Toggle status of offline and break time messages.</p>
                                        </div>
                                    </div>
                                    <div className="py-4">
                                        <h2 className="mb-2">After Hours</h2>
                                        <div className="flex mb-5">
                                            <div className="mr-5">
                                                <p className="mt-8">
                                                    <label class="inline-flex relative items-center cursor-pointer">
                                                        <input type="checkbox" value="" class="sr-only peer" onChange={(values) => toggleStatus(61, values.target.checked)} checked={offlineStatus} />
                                                        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                    </label>
                                                </p>
                                            </div>
                                            <div className="mr-2">
                                                <label className="block text-sm font-medium text-gray-700">From</label>
                                                <input
                                                    type="time"
                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                    value={offlineFrom}
                                                    onChange={(value) => changeDates(61, "from_time", value.target.value)}
                                                />
                                            </div>
                                            <div className="mr-5">
                                                <label className="block text-sm font-medium text-gray-700">To</label>
                                                <input
                                                    type="time"
                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                    value={offlineTo}
                                                    onChange={(value) => changeDates(61, "to_time", value.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-10">
                                            <p>
                                                <small>
                                                    {process.env.REACT_APP_COLOUR === "UC"
                                                        ? "Hi! ⭕ You've caught us during 😴💤🛌🏽 time... We'll be back online 8am-5pm! ✌️ ⭕"
                                                        : "Hi! 👋 You've caught us during 😴💤🛌🏽 time... We'll be back later! ✌️"}
                                                </small>
                                            </p>
                                        </div>

                                        <hr />

                                        <h2 className="mt-10 mb-2">Break Time</h2>
                                        <div className="flex">
                                            <div className="mr-5">
                                                <p className="mt-8">
                                                    <label class="inline-flex relative items-center cursor-pointer">
                                                        <input type="checkbox" value="" class="sr-only peer" onChange={(values) => toggleStatus(62, values.target.checked)} checked={breakStatus} />
                                                        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                    </label>
                                                </p>
                                            </div>
                                            <div className="mr-2">
                                                <label className="block text-sm font-medium text-gray-700">From</label>
                                                <input
                                                    type="time"
                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                    value={breakFrom}
                                                    onChange={(value) => changeDates(62, "from_time", value.target.value)}
                                                />
                                            </div>
                                            <div className="mr-5">
                                                <label className="block text-sm font-medium text-gray-700">To</label>
                                                <input
                                                    type="time"
                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                    value={breakTo}
                                                    onChange={(value) => changeDates(62, "to_time", value.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <p>
                                                <small>
                                                    {process.env.REACT_APP_COLOUR === "UC"
                                                        ? "Hi! ⭕ We are currently on a break... We'll be back a little later! ✌️⭕ Thank you for your patience! 😊"
                                                        : "Hi! 👋 We are currently on a break... We'll be back a little later! ✌️ Thank you for your patience! 😊"}
                                                </small>
                                            </p>
                                        </div>

                                        {process.env.REACT_APP_COLOUR === "UC" ? (
                                            <>
                                                <hr />

                                                <h2 className="mt-10 mb-2">Saturday and Sunday</h2>
                                                <div className="flex">
                                                    <div className="mr-5">
                                                        <p className="mt-8">
                                                            <label class="inline-flex relative items-center cursor-pointer">
                                                                <input type="checkbox" value="" class="sr-only peer" onChange={(values) => toggleStatus(63, values.target.checked)} checked={satSun} />
                                                                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                            </label>
                                                        </p>
                                                    </div>

                                                    <h2 className="mr-2 mt-7 font-bold">Saturday:</h2>

                                                    <div className="mr-2">
                                                        <label className="block text-sm font-medium text-gray-700">From</label>
                                                        <input
                                                            type="time"
                                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                            value={satFrom}
                                                            onChange={(value) => changeDates(63, "sat_from_time", value.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mr-5">
                                                        <label className="block text-sm font-medium text-gray-700">To</label>
                                                        <input
                                                            type="time"
                                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                            value={satTo}
                                                            onChange={(value) => changeDates(63, "sat_to_time", value.target.value)}
                                                        />
                                                    </div>

                                                    <h2 className=" ml-10 mr-2 mt-7 font-bold">Sunday:</h2>

                                                    <div className="mr-2">
                                                        <label className="block text-sm font-medium text-gray-700">From</label>
                                                        <input
                                                            type="time"
                                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                            value={sunFrom}
                                                            onChange={(value) => changeDates(63, "sun_from_time", value.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mr-5">
                                                        <label className="block text-sm font-medium text-gray-700">To</label>
                                                        <input
                                                            type="time"
                                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                            value={sunTo}
                                                            onChange={(value) => changeDates(63, "sun_to_time", value.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <p>
                                                        <small>
                                                            We are available online Saturdays from 9am-1pm and closed on Sundays. For emergencies call the emergency contact at your building or the
                                                            security control room on 0783588968
                                                        </small>
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </Tab.Panel>

                            {process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "UC" ? (
                                <>
                                    <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                        <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                            <h1 className="text-xl leading-6 font-light text-white capitalize">Move Out Pricing</h1>
                                        </div>

                                        <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                            <div className="flex justify-between items-center mb-5">
                                                <div>
                                                    <h2 className="text-lg leading-6 font-medium text-gray-900">Please enter default pricing</h2>
                                                </div>
                                            </div>
                                            <div className="py-4">
                                                <Formik
                                                    initialValues={default_tba}
                                                    enableReinitialize="true"
                                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                        console.log(values);
                                                        await axios({
                                                            method: "post",
                                                            url: `${process.env.REACT_APP_API_URL}default_tba_pricing`,
                                                            data: values,
                                                        })
                                                            .then(async (response) => {
                                                                toast(<NotificationToast title={"Pricing"} message="Pricing Updated" />);

                                                                await axios({
                                                                    method: "get",
                                                                    url: `${process.env.REACT_APP_API_URL}get_default_tba_pricing`,
                                                                }).then((response) => {
                                                                    setDefault_tba(response.data);
                                                                });
                                                            })
                                                            .catch((response) => {
                                                                toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                            });
                                                    }}
                                                >
                                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                            <div className="py-6 px-4 sm:p-6 xl:pb-8 max-w-6xl">
                                                                {process.env.REACT_APP_COLOUR === "QL" ? (
                                                                    <>
                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="inspection_fee" prepend="R" type="number" as={SettingsTextInput} label="Inspection Fee" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="consumables" prepend="R" type="number" as={SettingsTextInput} label="Consumables" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="fumigation" prepend="R" type="number" as={SettingsTextInput} label="Fumigation" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="labour" prepend="R" type="number" as={SettingsTextInput} label="Labour Rate / Hour" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="mould" prepend="R" type="number" as={SettingsTextInput} label="Mould" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="waste_removal" prepend="R" type="number" as={SettingsTextInput} label="Waste Removal" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="repaint_unit" prepend="R" type="number" as={SettingsTextInput} label="Repainting of entire unit" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="lounge_per_wall" prepend="R" type="number" as={SettingsTextInput} label="Lounge per wall" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="bedroom_per_wall" prepend="R" type="number" as={SettingsTextInput} label="Bedroom per wall" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="kitchen_per_wall" prepend="R" type="number" as={SettingsTextInput} label="Kitchen per wall" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="bathroom_per_wall" prepend="R" type="number" as={SettingsTextInput} label="Bathroom per wall" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="curtain_rail_replace" prepend="R" type="number" as={SettingsTextInput} label="Curtain rail replace" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="wall_bracket_replace" prepend="R" type="number" as={SettingsTextInput} label="Wall brackets replace" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="stop_ends_replace" prepend="R" type="number" as={SettingsTextInput} label="Stop ends replace" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="rail_runners_replace" prepend="R" type="number" as={SettingsTextInput} label="Rail runners replace" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="replace_front_door" prepend="R" type="number" as={SettingsTextInput} label="Replace front door" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="repair_front_door" prepend="R" type="number" as={SettingsTextInput} label="Repair front door" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_internal_door" prepend="R" type="number" as={SettingsTextInput} label="Replace internal door" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="repair_internal_door" prepend="R" type="number" as={SettingsTextInput} label="Repair internal door" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="front_door_handle" prepend="R" type="number" as={SettingsTextInput} label="Front door handle" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="internal_door_handle" prepend="R" type="number" as={SettingsTextInput} label="Internal door handle" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="lock_set" prepend="R" type="number" as={SettingsTextInput} label="Lock set" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="keys" prepend="R" type="number" as={SettingsTextInput} label="Keys" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_ceiling" prepend="R" type="number" as={SettingsTextInput} label="Replace ceiling" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="paint_ceiling" prepend="R" type="number" as={SettingsTextInput} label="Paint ceiling" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="replace_full_window" prepend="R" type="number" as={SettingsTextInput} label="Replace Full window" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_window_pane" prepend="R" type="number" as={SettingsTextInput} label="Replace Window Pane" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_window_stays" prepend="R" type="number" as={SettingsTextInput} label="Replace Window Stays" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_weather_strip" prepend="R" type="number" as={SettingsTextInput} label="Replace weather strip" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_window_handle" prepend="R" type="number" as={SettingsTextInput} label="Replace handle" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="floor_tiles" prepend="R" type="number" as={SettingsTextInput} label="Floor tiles / sq meter" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="kitchen_tiles" prepend="R" type="number" as={SettingsTextInput} label="Kitchen tiles / sq meter" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="light_fittings" prepend="R" type="number" as={SettingsTextInput} label="Light Fittings/ea" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="globes" prepend="R" type="number" as={SettingsTextInput} label="Globes/ea" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_plug_socket" prepend="R" type="number" as={SettingsTextInput} label="Replace Plug Socket" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="replace_light_switch" prepend="R" type="number" as={SettingsTextInput} label="Replace Light Switch" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="db_board_complete" prepend="R" type="number" as={SettingsTextInput} label="DB Board complete" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="circuit_breaker" prepend="R" type="number" as={SettingsTextInput} label="Circuit breaker" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="oven_isolator" prepend="R" type="number" as={SettingsTextInput} label="Oven Isolator" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_dstv" prepend="R" type="number" as={SettingsTextInput} label="Replace DSTV socket" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="replace_prepaid" prepend="R" type="number" as={SettingsTextInput} label="Replace prepaid Meter" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_meter" prepend="R" type="number" as={SettingsTextInput} label="Replace Replace Meter Card" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="towel_rail" prepend="R" type="number" as={SettingsTextInput} label="Towel Rail" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="toilet_paper_holder" prepend="R" type="number" as={SettingsTextInput} label="Toilet paper holder" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="soap_dish_holder" prepend="R" type="number" as={SettingsTextInput} label="Soap dish holder" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="shower_doors" prepend="R" type="number" as={SettingsTextInput} label="Shower doors" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="shower_door_runners" prepend="R" type="number" as={SettingsTextInput} label="Shower door runners" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="shower_rail" prepend="R" type="number" as={SettingsTextInput} label="Shower rail" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="shower_head" prepend="R" type="number" as={SettingsTextInput} label="Shower head" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="shower_waste" prepend="R" type="number" as={SettingsTextInput} label="Shower waste" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="shower_mixer" prepend="R" type="number" as={SettingsTextInput} label="Shower Mixer" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="toilet_bowl" prepend="R" type="number" as={SettingsTextInput} label="Toilet bowl" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="toilet_seat" prepend="R" type="number" as={SettingsTextInput} label="Toilet seat" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="cistern" prepend="R" type="number" as={SettingsTextInput} label="Cistern" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="toilet_flex" prepend="R" type="number" as={SettingsTextInput} label="Toilet Flex" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="flushing_mechanism" prepend="R" type="number" as={SettingsTextInput} label="Flushing Mechanism" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="wall_hung_basin" prepend="R" type="number" as={SettingsTextInput} label="Wall hung basin" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="basin_plug" prepend="R" type="number" as={SettingsTextInput} label="Basin plug" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="basin_mixer" prepend="R" type="number" as={SettingsTextInput} label="Basin Mixer" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="basin_flex" prepend="R" type="number" as={SettingsTextInput} label="Basin Flex" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="bathroom_cabinet_fitting" prepend="R" type="number" as={SettingsTextInput} label="Bathroom Cabinet" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="kitchen_sink" prepend="R" type="number" as={SettingsTextInput} label="Kitchen Sink" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="sink_plug" prepend="R" type="number" as={SettingsTextInput} label="Sink plug" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="kitchen_sink_mixer" prepend="R" type="number" as={SettingsTextInput} label="Kitchen Sink Mixer" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="undercounter_flex" prepend="R" type="number" as={SettingsTextInput} label="Undercounter flex" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="p_trap" prepend="R" type="number" as={SettingsTextInput} label="P Trap" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="stove_top" prepend="R" type="number" as={SettingsTextInput} label="Stove top" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="stove_knobs" prepend="R" type="number" as={SettingsTextInput} label="Stove knobs" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="controller_switches" prepend="R" type="number" as={SettingsTextInput} label="Controller switches" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="oven" prepend="R" type="number" as={SettingsTextInput} label="Oven" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="kitchen_counter_top" prepend="R" type="number" as={SettingsTextInput} label="Kitchen Counter Top" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="bedroom_cupboards" prepend="R" type="number" as={SettingsTextInput} label="Bedroom Cupboards" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="kitchen_cupboards" prepend="R" type="number" as={SettingsTextInput} label="Kitchen Cupboards" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="bathroom_cabinet" prepend="R" type="number" as={SettingsTextInput} label="Bathroom Cabinet" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="cupboard_handles" prepend="R" type="number" as={SettingsTextInput} label="Cupboard handles" />
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="cupboard_hinges" prepend="R" type="number" as={SettingsTextInput} label="Cupboard hinges" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="drawer_slides" prepend="R" type="number" as={SettingsTextInput} label="Drawer slides" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="loose_shelves" prepend="R" type="number" as={SettingsTextInput} label="Cupboards Loose Shelves" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="shelf_studs" prepend="R" type="number" as={SettingsTextInput} label="Cupboards Shelf Studs" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="door_handles" prepend="R" type="number" as={SettingsTextInput} label="Cupboards Door handless" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="repair_doors" prepend="R" type="number" as={SettingsTextInput} label="Cupboards Repair doors" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="router" prepend="R" type="number" as={SettingsTextInput} label="Router" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="cable" prepend="R" type="number" as={SettingsTextInput} label="Cable" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="plugs_sockets" prepend="R" type="number" as={SettingsTextInput} label="Plugs & sockets" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="labour_installation" prepend="R" type="number" as={SettingsTextInput} label="Labour installation" />
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="replace_earth" prepend="R" type="number" as={SettingsTextInput} label="Earth Leakage" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_shower_light_soil" prepend="R" type="number" as={SettingsTextInput} label="Shower Light Soil" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_shower_medium_soil" prepend="R" type="number" as={SettingsTextInput} label="Shower Medium Soil" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_shower_heavy_soil" prepend="R" type="number" as={SettingsTextInput} label="Shower Heavy Soil" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_oven_light_soil" prepend="R" type="number" as={SettingsTextInput} label="Oven & Hob Light Soil" />
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <br />

                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field
                                                                                    name="replace_oven_medium_soil"
                                                                                    prepend="R"
                                                                                    type="number"
                                                                                    as={SettingsTextInput}
                                                                                    label="Oven & Hob Medium Soil"
                                                                                />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_oven_heavy_soil" prepend="R" type="number" as={SettingsTextInput} label="Oven & Hob Heavy Soil" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_surface_light_soil" prepend="R" type="number" as={SettingsTextInput} label="Surfaces Light Soil" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field
                                                                                    name="replace_surface_medium_soil"
                                                                                    prepend="R"
                                                                                    type="number"
                                                                                    as={SettingsTextInput}
                                                                                    label="Surfaces Medium Soil"
                                                                                />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="replace_surface_heavy_soil" prepend="R" type="number" as={SettingsTextInput} label="Surfaces Heavy Soil" />
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <br />
                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="shower_tiles" prepend="R" type="number" as={SettingsTextInput} label="Shower Tiles" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="fv_shower_wheels" prepend="R" type="number" as={SettingsTextInput} label="FV Shower Wheels" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="u_shower_wheels" prepend="R" type="number" as={SettingsTextInput} label="U Shower Wheels" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="basin_stand" prepend="R" type="number" as={SettingsTextInput} label="Basin Stand" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="door_wood_filler" prepend="R" type="number" as={SettingsTextInput} label="Door Wood Filler" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="uc_walls" prepend="R" type="number" as={SettingsTextInput} label="Walls - (Washing)" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_ceiling" prepend="R" type="number" as={SettingsTextInput} label="Ceiling Boards" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_window" prepend="R" type="number" as={SettingsTextInput} label="Window/Window Frames/Latches/Panes" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field
                                                                                    name="uc_floor"
                                                                                    prepend="R"
                                                                                    type="number"
                                                                                    as={SettingsTextInput}
                                                                                    label="Flooring &Covering Tiles ( Burnt + Damage  Carperts)"
                                                                                />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field
                                                                                    name="uc_cupboards"
                                                                                    prepend="R"
                                                                                    type="number"
                                                                                    as={SettingsTextInput}
                                                                                    label="Cupboards / Cabinets / Countertops ( Burnt + Damage  Carperts)"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <br />
                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field
                                                                                    name="uc_enterance"
                                                                                    prepend="R"
                                                                                    type="number"
                                                                                    as={SettingsTextInput}
                                                                                    label="Enterance doors / Internal / Locks"
                                                                                />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_curtains" prepend="R" type="number" as={SettingsTextInput} label="Curtain Rails / Blinds" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_bed" prepend="R" type="number" as={SettingsTextInput} label="Bed / Mattress" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_desk" prepend="R" type="number" as={SettingsTextInput} label="Desk" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_chair" prepend="R" type="number" as={SettingsTextInput} label="Chair" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />
                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="uc_study" prepend="R" type="number" as={SettingsTextInput} label="Study Lamp" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_bin" prepend="R" type="number" as={SettingsTextInput} label="Dustbin" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_meter" prepend="R" type="number" as={SettingsTextInput} label="Prepaid Meter - If applicable" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field
                                                                                    name="uc_sink"
                                                                                    prepend="R"
                                                                                    type="number"
                                                                                    as={SettingsTextInput}
                                                                                    label="Sink Unit / Taps / Plug & Chain / Pipes"
                                                                                />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_stove" prepend="R" type="number" as={SettingsTextInput} label="Stove" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />
                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="uc_microwave" prepend="R" type="number" as={SettingsTextInput} label="Microwave" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_kettle" prepend="R" type="number" as={SettingsTextInput} label="Kettle" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_gas" prepend="R" type="number" as={SettingsTextInput} label="Gas Cylinder" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_fridge" prepend="R" type="number" as={SettingsTextInput} label="Fridge" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_panel" prepend="R" type="number" as={SettingsTextInput} label="Panel Heater" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />
                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="uc_smoke" prepend="R" type="number" as={SettingsTextInput} label="Smoke Detector" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_fire" prepend="R" type="number" as={SettingsTextInput} label="Fire Blanket" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_toilet" prepend="R" type="number" as={SettingsTextInput} label="Toilet Cistern / Bowl" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_sani_bin" prepend="R" type="number" as={SettingsTextInput} label="Sani She Bins (Female Units)" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_seat" prepend="R" type="number" as={SettingsTextInput} label="Toilet Seat" />
                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                        <br />
                                                                        <div className="flex">
                                                                            <div className="mr-6">
                                                                                <Field name="uc_roll" prepend="R" type="number" as={SettingsTextInput} label="Toilet Roll Holder" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_basin" prepend="R" type="number" as={SettingsTextInput} label="Basin / Plug / Chain" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_shower" prepend="R" type="number" as={SettingsTextInput} label="Shower" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_fitting" prepend="R" type="number" as={SettingsTextInput} label="Fittings (Lights, Towel Rails)" />
                                                                            </div>
                                                                            <div className="mr-6">
                                                                                <Field name="uc_other" prepend="R" type="number" as={SettingsTextInput} label="Other Costs Cleaning" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                <div className="mt-8">
                                                                    <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} icon={faSave} text="Submit" />
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                </>
                            ) : (
                                ""
                            )}

                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Appointment Types</h1>
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="flex justify-between items-center">
                                        <div className="pr-24">
                                            <p className="mt-1 text-sm text-gray-500">These appointments will be used to book specific appointments on the system</p>
                                        </div>
                                        <SettingsAddButton
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "SlideoverAppointmentForm",
                                                        modal_props: {
                                                            data: {
                                                                type: "Appointment",
                                                            },
                                                            callback: "getAppointments",
                                                            action: "add",
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="py-4 pl-5">
                                    {appointments.length > 0 &&
                                        appointments.map((appointment) => {
                                            return (
                                                <div key={appointment.id}>
                                                    <SettingsEditableRow
                                                        label={appointment.name}
                                                        key={"appointment" + appointment.id}
                                                        color={appointment.color}
                                                        small={appointment?.property ? (appointment?.property.name).split(" - ")[0] : ""}
                                                        managers={appointment.managers}
                                                        id={appointment.id}
                                                        value={appointment.name}
                                                        data={appointment}
                                                        type="appointment"
                                                        modal="SlideoverAppointmentForm"
                                                        callback={getAppointments()}
                                                        link={"appointment/" + appointment.id}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="divide-y divide-gray-400 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Managers</h1>
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="flex justify-between items-center">
                                        <div className="pr-24">
                                            <p className="mt-1 text-sm text-gray-500">Manage managers access to booking appointments</p>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="flex divide-x">
                                        <CustomButton text="Link user from System" onClick={() => dispatch(openModal({ modal_type: "ModalManagerSearch" }))} />
                                        <CustomButton text="Create a new user" onClick={() => dispatch(openModal({ modal_type: "ModalNewManager" }))} />
                                    </div>

                                    <div className="py-4 pl-5">
                                        {managers &&
                                            managers.length > 0 &&
                                            managers.map((manager) => {
                                                return (
                                                    <div key={manager.id}>
                                                        <SettingsEditableRow
                                                            label={manager.name + " " + manager.surname}
                                                            id={manager.id}
                                                            key={"manager" + manager.id}
                                                            value={manager.name + " " + manager.surname + " | " + manager.email}
                                                            managerSmall={manager?.schedule}
                                                            appointments={appointments}
                                                            data={manager}
                                                            type="manager"
                                                            link={"remove_manager/" + manager.id}
                                                            modal="SlideoverManagementForm"
                                                            callback={getManagers()}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="divide-y divide-gray-400 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">System</h1>
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="flex justify-between items-center">
                                        <div className="pr-24">
                                            <p className="mt-1 text-sm text-gray-500">Manage System Settings & Years</p>
                                        </div>
                                    </div>
                                    <br />
                                    <div>
                                        <CustomButton text="Add Year" onClick={() => setShowAddYear(showAddYear === false ? true : false)} />
                                        {showAddYear === true ? (
                                            <>
                                                <br />
                                                <Formik
                                                    initialValues={{ year: "" }}
                                                    validate={(values) => {
                                                        const errors = {};
                                                        if (!values.year) {
                                                            errors.year = "Required";
                                                        }
                                                        return errors;
                                                    }}
                                                    onSubmit={async (values, { setSubmitting }) => {
                                                        await axios({
                                                            method: "post",
                                                            url: `${process.env.REACT_APP_API_URL}add_year`,
                                                            data: values,
                                                        }).then((response) => {
                                                            dispatch(getSettings());
                                                            setShowAddYear(false);
                                                        });
                                                    }}
                                                >
                                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                        <form onSubmit={handleSubmit} className="flex">
                                                            <input
                                                                type="number"
                                                                name="year"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.year}
                                                                className="block font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm rounded-lg mr-2"
                                                            />
                                                            {errors.year && touched.year && errors.year}
                                                            <button
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                                className="relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 bg-dark_background hover:bg-dark_background focus:ring-dark_background"
                                                            >
                                                                Submit
                                                            </button>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="py-4 pl-5">
                                        <p className="font-semi-bold text-xl">System Years</p>
                                        <br />
                                        {years &&
                                            years.length > 0 &&
                                            years.map((year) => (
                                                <>
                                                    <span className={settings.year_id == year.id ? "text-green-600 font-bold" : ""}>{year.year}</span>
                                                    <br />
                                                </>
                                            ))}
                                        <br />
                                        <p className="font-semi-bold text-xl">Current Year: {settings.year.year}</p>
                                        <br />
                                        <CustomButton text="Update System Current Year" onClick={() => setUpdateYear(updateYear === false ? true : false)} />

                                        {updateYear === true ? (
                                            <>
                                                <small>Update Year</small>

                                                <Formik
                                                    initialValues={{ year_id: settings.year_id }}
                                                    validate={(values) => {
                                                        const errors = {};
                                                        if (!values.year_id) {
                                                            errors.year_id = "Required";
                                                        }
                                                        return errors;
                                                    }}
                                                    onSubmit={async (values, { setSubmitting }) => {
                                                        await axios({
                                                            method: "post",
                                                            url: `${process.env.REACT_APP_API_URL}update_year`,
                                                            data: values,
                                                        }).then((response) => {
                                                            dispatch(getSettings());
                                                            setUpdateYear(false);
                                                        });
                                                    }}
                                                >
                                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                                        <form onSubmit={handleSubmit} className="flex">
                                                            <select
                                                                name="year_id"
                                                                ref={filterProp}
                                                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                onChange={(value) => setFieldValue("year_id", value.target.value)}
                                                            >
                                                                {years &&
                                                                    years.length > 0 &&
                                                                    years.map((p) => (
                                                                        <option key={p.id} value={p.id} selected={p.id == settings.year_id ? "selected" : false}>
                                                                            {p.year}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                            <button
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                                className="relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 bg-dark_background hover:bg-dark_background focus:ring-dark_background"
                                                            >
                                                                Update
                                                            </button>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background ">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">System Rules</h1>
                                </div>
                                {process.env.REACT_APP_COLOUR === "UC" ? (
                                    <>
                                        <Formik
                                            initialValues={ValuesAccessControl} // get from db
                                            enableReinitialize="true"
                                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                dispatch(
                                                    updateSettings({
                                                        dayOfMonthDisableWifi: values.deactivatesWifiField,
                                                        dayOfMonthDisableBAccess: values.deactivatesBuildingAccessField,
                                                    })
                                                );
                                                console.log(values);
                                                await axios({
                                                    method: "post",
                                                    url: `${process.env.REACT_APP_API_URL}system_settings/update_disable_days`,
                                                    data: {
                                                        disable_wifi_days: values.deactivatesWifiField,
                                                        disable_building_access_days: values.deactivatesBuildingAccessField,
                                                    },
                                                })
                                                    .then((response) => {
                                                        dispatch(getSettings());
                                                        console.log(response);
                                                        if (response.data.error) {
                                                            toast(<NotificationToast title={"Something went wrong"} type="error" message={response.data.error} />);
                                                        } else {
                                                            toast(<NotificationToast title={"Account Arrears Rules"} message={"Account Arrears Rules has been updated"} />);
                                                        }
                                                    })
                                                    .catch((response) => {
                                                        toast(<NotificationToast title={"Something went wrong"} type="error" />);
                                                    });
                                            }}
                                        >
                                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                    <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                        <p className="mt-1 text-lg font-bold text-gray-500">Account Arrears Rules</p>

                                                        <div className="grid grid-cols-1 gap-6 mt-6 width=[50%]">
                                                            <Field
                                                                name="deactivatesWifiField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Day of the month the system disables Wifi for accounts in arrears: "
                                                            />
                                                            <Field
                                                                name="deactivatesBuildingAccessField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Day of the month the system disables building access for accounts in arrears: "
                                                            />
                                                            <div className="mt-4">
                                                                <SubmitButton
                                                                    name="SaveAccountArrears"
                                                                    disabled={!isValid || isSubmitting || !dirty || settingsState.loading}
                                                                    isSubmitting={settingsState.loading}
                                                                    icon={faSave}
                                                                    text="Save"
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        <Formik
                                            initialValues={GuestRules} // get from db
                                            enableReinitialize="true"
                                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                dispatch(
                                                    updateSettings({
                                                        num_free_guests: values.AmountOfFreeGuests,
                                                        num_paid_guests: values.AmountOfPaidGuestsField,
                                                        cost_per_paid_guest: values.CostPerGuestField,
                                                        length_of_stay_limit: values.LengthStayPerGuestField,
                                                    })
                                                );
                                                await axios({
                                                    method: "post",
                                                    url: `${process.env.REACT_APP_API_URL}system_settings/update-guest-settings`,
                                                    data: {
                                                        num_free_guests: values.AmountOfFreeGuests,
                                                        num_paid_guests: values.AmountOfPaidGuestsField,
                                                        cost_per_paid_guest: values.CostPerGuestField,
                                                        length_of_stay_limit: values.LengthStayPerGuestField,
                                                    },
                                                })
                                                    .then((response) => {
                                                        dispatch(getSettings());
                                                        console.log(response.data);
                                                        if (response.data.error) {
                                                            toast(<NotificationToast title={"Something went wrong"} type="error" message={response.data.error} />);
                                                        } else {
                                                            toast(<NotificationToast title={"Guest Rules"} message={"Guest Rules has been updated"} />);
                                                        }
                                                    })
                                                    .catch((response) => {
                                                        toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                    });
                                            }}
                                        >
                                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                    <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                        <div className="grid grid-cols-1 gap-6 mt-6 width=[50%]">
                                                            <p className="mt-1 text-lg font-bold text-gray-500">Guest Rules (per tenant)</p>
                                                            <Field
                                                                name="AmountOfFreeGuests"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="No. of Free Guests:"
                                                                tooltip="The amount of free guests per tenant."
                                                            />
                                                            <Field
                                                                name="AmountOfPaidGuestsField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="No. of Paid Guests:"
                                                                tooltip="The amount of paid guests allowed per tenant."
                                                            />
                                                            <Field name="CostPerGuestField" type="number" as={SettingsTextInput} label="Cost per Paid Guest: " tooltip="The cost per paid guest." />
                                                            <Field
                                                                name="LengthStayPerGuestField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Length of stay limit in days: "
                                                                tooltip="The length of a guests allowed stay."
                                                            />

                                                            <div className="mt-8">
                                                                <SubmitButton
                                                                    name="SaveGuestRules"
                                                                    disabled={!isValid || isSubmitting || !dirty || settingsState.loading}
                                                                    isSubmitting={settingsState.loading}
                                                                    icon={faSave}
                                                                    text="Save"
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                ) : (
                                    <>
                                        <Formik
                                            initialValues={QlValuesAccessControl} // get from db
                                            enableReinitialize="true"
                                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                dispatch(
                                                    updateSettings({
                                                        dayOfMonthDisableWifi: values.qldeactivatesWifiField,
                                                        dayOfMonthDisableBAccess: values.qldeactivatesBuildingAccessField,
                                                    })
                                                );
                                                console.log(values);
                                                await axios({
                                                    method: "post",
                                                    url: `${process.env.REACT_APP_API_URL}system_settings/update_disable_days`,
                                                    data: {
                                                        disable_wifi_days: values.qldeactivatesWifiField,
                                                        disable_building_access_days: values.qldeactivatesBuildingAccessField,
                                                    },
                                                })
                                                    .then((response) => {
                                                        dispatch(getSettings());
                                                        console.log(response);
                                                        if (response.data.error) {
                                                            toast(<NotificationToast title={"Something went wrong"} type="error" message={response.data.error} />);
                                                        } else {
                                                            toast(<NotificationToast title={"Account Arrears Rules"} message={"Account Arrears Rules has been updated"} />);
                                                        }
                                                    })
                                                    .catch((response) => {
                                                        toast(<NotificationToast title={"Something went wrong"} type="error" />);
                                                    });
                                            }}
                                        >
                                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                    <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                        <p className="mt-1 text-lg font-bold text-gray-500">Account Arrears Rules</p>

                                                        <div className="grid grid-cols-1 gap-6 mt-6 width=[50%]">
                                                            <Field
                                                                name="qldeactivatesWifiField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Day of the month the system disables Wifi for accounts in arrears: "
                                                            />
                                                            <Field
                                                                name="qldeactivatesBuildingAccessField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Day of the month the system disables building access for accounts in arrears: "
                                                            />
                                                            <div className="mt-4">
                                                                <SubmitButton
                                                                    name="SaveAccountArrears"
                                                                    disabled={!isValid || isSubmitting || !dirty || settingsState.loading}
                                                                    isSubmitting={settingsState.loading}
                                                                    icon={faSave}
                                                                    text="Save"
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        <Formik
                                            initialValues={QlGuestRules} // get from db
                                            enableReinitialize="true"
                                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                dispatch(
                                                    updateSettings({
                                                        num_free_guests: values.AmountOfBachelorGuests,
                                                        num_paid_guests: values.AmountOf1bedroomGuestsField,
                                                        qcost_per_paid_guest: values.AmountOf2bedroomGuestsField,
                                                        length_of_stay_limit: values.QlLengthStayPerGuestField,
                                                    })
                                                );
                                                await axios({
                                                    method: "post",
                                                    url: `${process.env.REACT_APP_API_URL}system_settings/update-guest-settings`,
                                                    data: {
                                                        num_bachelor_guests: values.AmountOfBachelorGuests,
                                                        num_1bed_guests: values.AmountOf1bedroomGuestsField,
                                                        num_2bed_guests: values.AmountOf2bedroomGuestsField,
                                                        length_of_stay_limit: values.QlLengthStayPerGuestField,
                                                    },
                                                })
                                                    .then((response) => {
                                                        dispatch(getSettings());
                                                        console.log(response.data);
                                                        if (response.data.error) {
                                                            toast(<NotificationToast title={"Something went wrong"} type="error" message={response.data.error} />);
                                                        } else {
                                                            toast(<NotificationToast title={"Guest Rules"} message={"Guest Rules has been updated"} />);
                                                        }
                                                    })
                                                    .catch((response) => {
                                                        toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                    });
                                            }}
                                        >
                                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                                    <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                        <div className="grid grid-cols-1 gap-6 mt-6 width=[50%]">
                                                            <p className="mt-1 text-lg font-bold text-gray-500">Guest Rules (per tenant)</p>
                                                            <Field
                                                                name="AmountOfBachelorGuests"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Max No. of guests for bachelor:"
                                                                tooltip="The amount of guests per bachelor."
                                                            />
                                                            <Field
                                                                name="AmountOf1bedroomGuestsField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Max No. of guests for 1 bedroom:"
                                                                tooltip="The amount of guests allowed 1 bedroom."
                                                            />
                                                            <Field
                                                                name="AmountOf2bedroomGuestsField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Max No. of guests for 2 bedroom: "
                                                                tooltip="The amount of guests allowed 2 bedroom."
                                                            />
                                                            <Field
                                                                name="QlLengthStayPerGuestField"
                                                                type="number"
                                                                as={SettingsTextInput}
                                                                label="Length of stay limit in days: "
                                                                tooltip="The length of a guests allowed stay."
                                                            />

                                                            <div className="mt-8">
                                                                <SubmitButton
                                                                    name="SaveGuestRules"
                                                                    disabled={!isValid || isSubmitting || !dirty || settingsState.loading}
                                                                    isSubmitting={settingsState.loading}
                                                                    icon={faSave}
                                                                    text="Save"
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                )}
                            </Tab.Panel>

                            <Tab.Panel className="divide-y divide-gray-200 xl:col-span-9">
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Tenant Actions</h1>
                                </div>

                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <div className="py-4">
                                        <h2 className="mt-1 text-lg font-bold text-gray-500">Check wifi access</h2>
                                        <p className="mt-1 mb-2 text-sm text-gray-500">Check if students have the correct wifi access and enabled/disable accordingly.</p>
                                        <CustomButton text="Check wifi access" onClick={() => triggerCron("check-wifi-access")} />
                                    </div>
                                    <div className="py-4">
                                        <h2 className="mt-1 text-lg font-bold text-gray-500">Check building access</h2>
                                        <p className="mt-1 mb-2 text-sm text-gray-500">Check if students have the correct building access and enabled/disable accordingly.</p>
                                        <CustomButton text="Check building access" onClick={() => triggerCron("check-building-access")} />
                                    </div>
                                    <div className="py-4">
                                        <h2 className="mt-1 text-lg font-bold text-gray-500">Send payment links</h2>
                                        <p className="mt-1 mb-2 text-sm text-gray-500">Send payment links to students</p>
                                        <CustomButton text="Send payment links" onClick={() => triggerCron("invoice-tenants")} />
                                    </div>
                                    <div className="py-4">
                                        <h2 className="mt-1 text-lg font-bold text-gray-500">Send guest access expire reminder</h2>
                                        <p className="mt-1 mb-2 text-sm text-gray-500">Send a reminder for guests that have today as the last day of their visit</p>
                                        <CustomButton text="Send guest reminders" onClick={() => triggerCron("remind-guest-expire")} />
                                    </div>
                                    <div className="py-4">
                                        <h2 className="mt-1 text-lg font-bold text-gray-500">Revoke expired guest access</h2>
                                        <p className="mt-1 mb-2 text-sm text-gray-500">Revoke access for guests that have today as the last day of their visit</p>
                                        <CustomButton text="Revoke guest access" onClick={() => triggerCron("check-guest-access")} />
                                    </div>
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </>
    );
}
