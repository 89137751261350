import React, { useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function PaymentsReport() {
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [expandedRows, setExpandedRows] = useState(new Set());

    const toggleRow = (index) => {
        const newExpandedRows = new Set(expandedRows);
        if (newExpandedRows.has(index)) {
            newExpandedRows.delete(index);
        } else {
            newExpandedRows.add(index);
        }
        setExpandedRows(newExpandedRows);
    };

    const formatTransactionResult = (result) => {
        try {
            const parsed = typeof result === 'string' ? JSON.parse(result) : result;
            return JSON.stringify(parsed, null, 2);
        } catch (e) {
            return result || 'No transaction data available';
        }
    };

    const renderReport = (data) => {
        if (!data || data.length === 0) {
            return <p className="text-gray-500 italic">No payments found for the selected period.</p>;
        }

        return (
            <div className="bg-white rounded-lg shadow overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Date
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Reference
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Location
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Module
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Reason
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Amount
                            </th>
                            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Details
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((payment, index) => (
                            <React.Fragment key={index}>
                                <tr className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {moment(payment.payment_date).format("DD MMM YYYY")}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {payment.booking_reference}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {payment.location}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {payment.payment_module}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                payment.status === 'Complete' ? 'bg-green-100 text-green-800' :
                                                    payment.status === 'Failed' ? 'bg-red-100 text-red-800' :
                                                        'bg-yellow-100 text-yellow-800'
                                            }`}>
                                                {payment.status}
                                            </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {payment.reason}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                        {new Intl.NumberFormat('en-ZA', {
                                            style: 'currency',
                                            currency: 'ZAR'
                                        }).format(payment.amount)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                        <button
                                            onClick={() => toggleRow(index)}
                                            className="text-primary hover:text-primary-dark focus:outline-none"
                                        >
                                            {expandedRows.has(index) ? (
                                                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                                </svg>
                                            ) : (
                                                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                                </svg>
                                            )}
                                        </button>
                                    </td>
                                </tr>
                                {expandedRows.has(index) && (
                                    <tr>
                                        <td colSpan="8" className="px-6 py-4 bg-gray-50">
                                            <div className="text-sm text-gray-900">
                                                <h4 className="font-medium mb-2">Transaction Details:</h4>
                                                <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto whitespace-pre-wrap">
                                                        {formatTransactionResult(payment.transaction_result)}
                                                    </pre>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
            <h1 className="text-2xl font-bold mb-6">Payments Report</h1>

            <Formik
                initialValues={{}}
                onSubmit={async (values, {setSubmitting}) => {
                    const formattedFromDate = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
                    const formattedToDate = toDate ? moment(toDate).format("YYYY-MM-DD") : null;
                    setLoading(true);

                    try {
                        const response = await axios.get(`${process.env.REACT_APP_API_URL}payments-report`, {
                            params: {
                                start_date: formattedFromDate,
                                end_date: formattedToDate,
                            },
                        });
                        setReportData(response.data);
                    } catch (error) {
                        console.error("Error fetching report:", error);
                    } finally {
                        setLoading(false);
                        setSubmitting(false);
                    }
                }}
            >
                {({handleSubmit, isSubmitting}) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="bg-white rounded-lg shadow p-6 mb-6">
                            <div className="grid md:grid-cols-2 gap-6 mb-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">From Date</label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={setFromDate}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                                        dateFormat="d MMMM yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">To Date</label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={setToDate}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                                        dateFormat="d MMMM yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                            </div>
                            <SubmitButton
                                name="Submit"
                                disabled={isSubmitting || !fromDate || !toDate}
                                isSubmitting={isSubmitting}
                                text="Generate Report"
                            />
                        </div>

                        <div className="mt-6">
                            {loading ? (
                                <div className="flex justify-center items-center py-8">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                                </div>
                            ) : (
                                renderReport(reportData)
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}